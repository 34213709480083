import React from "react";
import './Faq.scss';
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import closeMenu from '../../Images/closeMenu.png'
import telephone from '../../Images/telephone.png'
import book from '../../Images/book.png'
// images
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"


export default function Faq({ CitiePhone, Blogs, FaqList }) {
    const navigate = useNavigate()
    const [faqlistFilter, setfaqlistFilter] = useState(FaqList);
    const [faqlistInputValue, setfaqlistInputValue] = useState("");
    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());
    const location = useLocation()
    useEffect(() => {
        // console.log(location.hash);
    }, []);

    return (


        <div className="Faq">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>FAQ | BHO Air Duct Cleaning - Your Air Duct Service Questions & Answeres</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="FAQ | BHO Air Duct Cleaning - Your Air Duct Service Questions Answered" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="FAQ | BHO Air Duct Cleaning - Your Air Duct Service Questions Answered" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* Blog Tags */}
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content="article" />
                <meta name="SubCategories" content="article" />
                <meta name="FunnelStage" content="upper" />
                <meta name="Objective" content="education, seo" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.bhoairductcleaning.com/faq" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="EFind answers to common questions about chimney, inspections, cleanings, and repairs. Explore our FAQ page for expert advice and insights from BHO Air Duct Cleaning." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="EFind answers to common questions about chimney, inspections, cleanings, and repairs. Explore our FAQ page for expert advice and insights from BHO Air Duct Cleaning." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="FAQ | BHO Air Duct Cleaning - Your Air Duct Service Questions Answered" data-react-helmet="true" />
                <meta name="keywords" content="Air Duct Cleaning, Air Duct Inspection, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Repair, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Repair, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct services, Air Duct service, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Repair, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Insulation, Air Duct Installation, Air Duct Liner Repair, Air Duct Pointing, Air Duct Rainp Installation, Air Duct Cleaning, Air Duct Crown Repair, Air Duct Restoration, Air Duct Flue Installation, Air Duct Flue Repair, ​Air Duct Construction, Air Duct Vent Installation, Air Duct Flashing, Flexible Air Duct Liner Installation, Air Duct Damper Repair, Chimneyp Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rainp Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplacep, Faq Page" data-react-helmet="true" />
            </Helmet>
            <div className="FaqMainBanner">
                <h1>Air Duct Cleaning FAQs</h1>
                <h2>For expert Air Duct Cleaning services, reach out to BHO Air Duct Cleaning. Our certified technicians are committed to delivering reliable, high-quality repairs to ensure your Air Duct remains safe and fully functional.</h2>
                <h5>Categories</h5>
                <div className="FaqMainBannerCategories">
                    <a href="/faq#chimney-cleaning">Air Duct Cleaning</a>
                    <a href="/faq#chimney-inspection">Air Duct Inspection</a>
                    <a href="/faq#chimney-repair">Air Duct Repair</a>
                    <a href="/faq#chimney-sweep">Air Duct Cleaning</a>
                    <a href="/faq#chimney-liner-installation">Air Duct Liner Installation</a>
                    <a href="/faq#chimney-crown-repair">Air Duct Crown Repair</a>
                    <a href="/faq#chimney-flashing-repair">Air Duct Draft Issues</a>
                </div>
                <a className="BlogAsideCall" href={`tel:${CitiePhone}`}>CALL 866-645-9692<img src={telephone} alt="Air Duct Cleaning In BHO, | Air Duct Repair In BHO, | Air Duct Services In BHO, | Air Duct Inspection In BHO," /></a>
                <a className="BlogAsideCall" href="/book">SCHEDULE ONLINE<img src={book} alt="Air Duct Cleaning In BHO, | Air Duct Repair In BHO, | Air Duct Services In BHO, | Air Duct Inspection In BHO," /></a>


            </div>
            {/* FaqMainList */}
            <div className="FaqMainList">
                {faqlistInputValue ?
                    <div className="FaqList">
                        <div className="FaqListHeaderResults">
                            <h6>Results</h6>
                            <h5 onClick={() => setfaqlistInputValue("")}>Restart Results</h5>
                        </div>
                        {faqlistFilter.filter(a =>
                            a.question.toLocaleLowerCase().includes(faqlistInputValue.toLocaleLowerCase()) ||
                            a.questionPath.toLocaleLowerCase().includes(faqlistInputValue.toLocaleLowerCase())
                        ).map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}

                        {faqlistFilter.length ?
                            <div className="FaqListNoResult">
                                <h1>We're sorry, but your search did not return any results.</h1>
                            </div>
                            : null}

                        <div className="FaqPageHeaderImagePromise">
                            <div className="FaqPageHeaderImagePromiseTop">
                                <div className="FaqPageHeaderImagePromiseTopLeft">
                                    {/* <img src={Logo} alt="Air Duct Cleaning In BHO, | Air Duct Repair In BHO, | Air Duct Services In BHO, | Air Duct Inspection In BHO," /> */}
                                    <h2>BHO Air Duct Cleaning promise</h2>
                                </div>
                                <a href="/apply">More info</a>
                            </div>
                            <p>For professional Air Duct Cleaning services, contact BHO Sweep. Our certified technicians are dedicated to providing reliable and high-quality repairs to keep your Air Duct safe and functional.</p>
                        </div>
                    </div>
                    :
                    <div className="FaqList">
                        <h6 id="chimney-cleaning">Air Duct Cleaning</h6>
                        {faqlistFilter.filter(a => a.category == "Air Duct Cleaning").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="chimney-inspection">Air Duct Inspection</h6>
                        {FaqList.filter(a => a.category == "Air Duct Inspection").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="chimney-repair">Air Duct Repair</h6>
                        {FaqList.filter(a => a.category == "Air Duct Repair").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="chimney-sweep">Air Duct Cleaning</h6>
                        {FaqList.filter(a => a.category == "Air Duct Cleaning").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="chimney-liner-installation">Air Duct Liner Installation</h6>
                        {FaqList.filter(a => a.category == "Air Duct Liner Installation").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="chimney-crown-repair">Air Duct Crown Repair</h6>
                        {FaqList.filter(a => a.category == "Air Duct Inspection").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="Chimney-Draft-Issues">Air Duct Draft Issues</h6>
                        {FaqList.filter(a => a.category == "Air Duct Draft Issues").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                    </div>
                }
                <aside className="FaqSearch">
                    <h1>Categories</h1>
                    <div className="FaqMainBannerCategories">
                        <a style={location.hash == "#chimney-cleaning" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#chimney-cleaning">Air Duct Cleaning</a>
                        <a style={location.hash == "#chimney-inspection" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#chimney-inspection">Air Duct Inspection</a>
                        <a style={location.hash == "#chimney-repair" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#chimney-repair">Air Duct Repair</a>
                        <a style={location.hash == "#chimney-sweep" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#chimney-sweep">Air Duct Cleaning</a>
                        <a style={location.hash == "#chimney-liner-installation" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#chimney-liner-installation">Air Duct Liner Installation</a>
                        <a style={location.hash == "#chimney-crown-repair" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#chimney-crown-repair">Air Duct Crown Repair</a>
                        <a style={location.hash == "#Chimney-Draft-Issues" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#chimney-flashing-repair">Air Duct Draft Issues</a>
                    </div>
                    <h1>Search for question</h1>
                    <div className="FaqSearchInput">
                        <input
                            value={faqlistInputValue || ''}
                            onFocus={() => {
                                window.screen.width >= 850 ? window.scrollTo(0, 420) : window.scrollTo(0, 570)
                            }}
                            onChange={(e) => {
                                setfaqlistInputValue(e.target.value)
                                window.screen.width >= 850 ? window.scrollTo(0, 420) : window.scrollTo(0, 570)

                            }} type="text" placeholder="Search..." />
                        {faqlistInputValue ?
                            <img onClick={() => setfaqlistInputValue("")} src={closeMenu} alt="Air Duct Cleaning In BHO, | Air Duct Repair In BHO, | Air Duct Services In BHO, | Air Duct Inspection In BHO," />
                            :
                            null}
                    </div>
                </aside>
            </div>

            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>
                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                            <article>

                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>

                        </a>
                    )}
                </div>
            </div>


            <NavigatorPath />

        </div >
    )
}

