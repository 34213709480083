import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Contact from '../Components/Contact/Contact'
import HomePage from '../Components/HomePage/HomePage'
import HomePageGoogle from '../Components/HomePageGoogle/HomePageGoogle'
import Blog from '../Components/Blog/Blog'
import BlogPage from '../Components/BlogPage/BlogPage'
import SiteMap from '../Tools/SiteMap/SiteMap'
import NotFound from '../Tools/NotFound/NotFound'
import Faq from '../Components/Faq/Faq'
import FaqPage from '../Components/FaqPage/FaqPage'
import Thanks from '../Tools/Thanks/Thanks'
import ServicesPageServicesPage from '../Components/ServicesPage/ServicesPage'
import BookAnAppointment from '../Components/BookAnAppointment/BookAnAppointment'
import ReviewsCust from '../Components/ReviewsCust/ReviewsCust'
import ServicesAll from '../Components/ServicesAll/ServicesAll'
import HomePageCity from '../Components/HomePageCity/HomePageCity'
import LocationRivews from '../Components/LocationRivews/LocationReviews'
import ThanksContact from '../Tools/ThanksContact/ThanksContact'


export default function Main({ CitiePhone, CitiesData, navigateTo, Blogs, FaqList, WebSitePages }) {

    return (
        // <div>
        <Routes>
            <Route path="/" element={<HomePage CitiePhone={CitiePhone} navigateTo={navigateTo} Blogs={Blogs} FaqList={FaqList} />} />
            <Route path="/local" element={<HomePageGoogle CitiePhone={CitiePhone}  CitiesData={CitiesData} navigateTo={navigateTo} Blogs={Blogs} FaqList={FaqList} />} />
            {/* Services */}
            <Route path="/city/:city" element={<HomePageCity CitiePhone={CitiePhone} navigateTo={navigateTo} Blogs={Blogs} FaqList={FaqList} />} />
            {/* Services */}
            <Route path="/services" element={<ServicesAll CitiePhone={CitiePhone} />} />
            <Route path="/service/:service" element={<ServicesPageServicesPage CitiePhone={CitiePhone} navigateTo={navigateTo} Blogs={Blogs} FaqList={FaqList} />} />
            {/* Blog */}
            <Route path="/blog" element={<Blog CitiePhone={CitiePhone} Blogs={Blogs} FaqList={FaqList} />} />
            <Route path="/blog/:id" element={<BlogPage CitiePhone={CitiePhone} Blogs={Blogs} FaqList={FaqList} />} />
            {/* Faq */}
            <Route path="/faq" element={<Faq CitiePhone={CitiePhone} FaqList={FaqList} Blogs={Blogs} />} />
            <Route path="/faq/:id" element={<FaqPage CitiePhone={CitiePhone} FaqList={FaqList} Blogs={Blogs} />} />
     
            {/*  */}
            <Route path="/contact" element={<Contact CitiePhone={CitiePhone} />} />
            <Route path="/reviews" element={<ReviewsCust CitiePhone={CitiePhone} />} />
            <Route path="/reviews/local" element={<LocationRivews CitiePhone={CitiePhone} />} />
            <Route path="/book" element={<BookAnAppointment CitiesData={CitiesData}  CitiePhone={CitiePhone} />} />
            <Route path="/thanks" element={<Thanks CitiePhone={CitiePhone} navigateTo={navigateTo} />} />
            <Route path="/thankscontact" element={<ThanksContact CitiePhone={CitiePhone} navigateTo={navigateTo} />} />

            {/* SiteMap */}
            {["/sitemaps", "/sitemap", "/widgets"].map((path, index) =>
                <Route path={path} key={index} element={<SiteMap CitiePhone={CitiePhone} Blogs={Blogs} FaqList={FaqList} WebSitePages={WebSitePages} />} />
            )}
            <Route path="/sitemap/:id" element={<SiteMap CitiePhone={CitiePhone} Blogs={Blogs} FaqList={FaqList} WebSitePages={WebSitePages} />} />
            {/* 404 */}
            <Route path="*" element={<NotFound CitiePhone={CitiePhone} />} />
        </Routes>
        // </div>
    )
}
