import React, { useEffect } from "react";
import './HomePageCity.scss';
import $ from 'jquery';
import { useState } from "react";
import { Helmet } from "react-helmet";
// images
import appcalendar from '../../Images/appcalendar.png'
import appdropshipping from '../../Images/appdropshipping.png'
import appsearch from '../../Images/appsearch.png'
import appwallet from '../../Images/appwallet.png'

import Chimney1 from '../../Images/PhotoImg1.jpg'
import Chimney2 from '../../Images/PhotoImg2.jpg'
import Chimney3 from '../../Images/PhotoImg3.jpg'
import Chimney4 from '../../Images/PhotoImg4.jpg'
import book from '../../Images/book.png'
import bgnobgs from '../../Images/bgnobgs.png'
import AreaData from '../../Components/ServicesPage/AreaData'
import CitiesData from './CitiesData'
import HomeAdvisor from '../../Images/HomeAdvisor.png'
import BBB from '../../Images/BBB.png'
import Angie from '../../Images/Angie.png'
import YELP from '../../Images/YELP.png'
import Google from '../../Images/Google.png'
import Trust from '../../Images/Trust.png'
import Porch from '../../Images/Porch.png'
import { useLocation } from "react-router-dom";

export default function HomePageCity({ FaqList, Blogs }) {
    const [TheCity, setTheCity] = useState({});
    const location = useLocation()

    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }

    const getSpecificNumberFaq = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberFaq()
        }
    }
    const [getNumberFaq, setgetNumberFaq] = useState(getSpecificNumberFaq());
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());

    useEffect(() => {
        const SpecificCityTo = CitiesData.find(a => a.url == location.pathname.split('/')[2])
        setTheCity(SpecificCityTo);

    }, []);

    return (
        <div className="HomePageCity">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>BHO Air Duct Cleaning {TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""} | Expert Air Duct Cleaning | 866-645-9692</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content={`BHO Air Duct Cleaning ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""} | Expert Air Duct Cleaning | 866-645-9692`} data-react-helmet="true" />
                <meta name="og:title" property="og:title" content={`BHO Air Duct Cleaning ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""} | Expert Air Duct Cleaning | 866-645-9692`} data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content={`https://www.bhoairductcleaning.com/city/${TheCity && TheCity.City ? TheCity.url : ""}`} data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content={`Air Duct Cleaning in ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""} provides top-quality Air Duct cleaning, inspections, and repairs to keep your home safe and efficient. Trust our experienced professionals for all your Air Duct service needs.`} data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content={`Air Duct Cleaning in ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""} provides top-quality Air Duct cleaning, inspections, and repairs to keep your home safe and efficient. Trust our experienced professionals for all your Air Duct service needs.`} data-react-helmet="true" />
                <meta name="keywords" content={`Air Duct Cleaning ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""}, Air Duct repair ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""} ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""}, Air Duct Inspection, Fireplace Inspection${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""}, Fireplace Cleaning, Air Duct Cleaning ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""}, Air Duct Maintenance ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""}, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct services, Air Duct service, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Insulation, Air Duct Installation, Air Duct Liner Repair, Air Duct Pointing, Air Duct Rainp Installation, Air Duct Repair, Air Duct Crown Repair, Air Duct Restoration, Air Duct Flue Installation, Air Duct Flue Repair, ​Air Duct Construction, Air Duct Vent Installation, Air Duct Flashing, Flexible Air Duct Liner Installation, Air Duct Damper Repair, Chimneyp Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rainp Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplacep`} data-react-helmet="true" />
            </Helmet>

            {/* MainBanner */}
            <div className="HomePageCityLocation">
                <h1>{TheCity && TheCity.City ? TheCity.City.toUpperCase() + ", " + TheCity.StateID : ""}</h1>
            </div>

            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>The Air Duct Experts You've Trusted for Over 50 Years In {TheCity.City}, {TheCity.State} </h1>
                    <h2>Servicing {TheCity.City} And Surrounding Areas - BHO Air Duct Cleaning Near Me In {TheCity.City}, {TheCity.StateID}</h2>
                    <a href={`tel:866-645-9692`}>Call Us Now!</a>
                    <a href="/book">SCHEDULE ONLINE<img src={book} alt={`Air Duct Cleaning ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""}`} /></a>
                </div>
                <div className="MainBannerRight">
                    <img src={bgnobgs} alt={`Air Duct Repair ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""}`} />
                    <div className="MainBannerRightBubble1"></div>
                    <div className="MainBannerRightBubble2"></div>
                </div>
            </div>
            <div className="BookAnAppointmentMainComponentEmergery">
                <div className="BookAnAppointmentMainComponentRates">
                    <div className="BookAnAppointmentMainComponentRatesImages">
                        <img src={YELP} alt={`BHO Air Duct Cleaning Cleaning ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""} Yelp Review`} />
                        <img src={Angie} alt={`BHO Air Duct Cleaning Cleaning ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""} Angie Review`} />
                        <img src={HomeAdvisor} alt={`BHO Air Duct Cleaning Cleaning ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""} HomeAdvisor Review`} />
                        <img src={BBB} alt={`BHO Air Duct Cleaning Cleaning ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""} BBB Review`} />
                        <img src={Google} alt={`BHO Air Duct Cleaning Cleaning ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""} Google Review`} />
                        <img src={Porch} alt={`BHO Air Duct Cleaning Cleaning ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""} Porch Review`} />
                        <img src={Trust} alt={`BHO Air Duct Cleaning Cleaning ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""} Trust Review`} />
                    </div>
                    <h1>Customer Testimonials</h1>
                    <h2>⭐⭐⭐⭐⭐</h2>
                    {TheCity && TheCity.reviews ? TheCity.reviews.map((A, index) =>
                        <h3 key={index}>{A}</h3>
                    ) : null}
                    <h3>
                        <a href={`/reviews/local`}>MORE FROM OUR CUSTOMERS &gt;</a>
                    </h3>

                </div>
                <span className="GoogleButt">
                    <h6>5 Stars - Based on 1,800 Reviews</h6>
                    <h6 className="GoogleButtBooms">
                        Within 5 mi • Within 20 mi • Open now • Residential • Top rated • Prices • Online estimates • Replacement
                    </h6>
                </span>
                <div className="BookAnAppointmentMainComponentEmergeryBox">
                    <a href={`tel:866-645-9692`}>For emergency service Call: 866-645-9692</a>
                </div>
            </div>
            <div className="MainBanner2Top2">
                <div className="MainBanner2TopBox">
                    <h2>OUR Air Duct SERVICES</h2>
                    <div className="MainBanner2TopBoxMenu">
                        <a href="/service/Chimney-Cleaning" className="MainBanner2TopBoxBox">
                            <img src={appcalendar} alt={`Air Duct Cleaning ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""}`} />
                            <h3>Air Duct Cleaning</h3>
                            <h2>Remove creosote buildup and debris for a safer, more efficient fireplace and improved indoor air quality.</h2>
                            <h4>EXPLORE THIS SERVICE</h4>
                        </a>
                        <a href="/service/Chimney-Inspection" className="MainBanner2TopBoxBox">
                            <img src={appdropshipping} alt={`Air Duct Inspection ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""}`} />
                            <h3>Air Duct Inspection</h3>
                            <h2>Ensure your chimney’s structural integrity and functionality with a thorough inspection to prevent hazards and issues.</h2>
                            <h4>EXPLORE THIS SERVICE</h4>
                        </a>
                        <a href="/service/Chimney-Maintenance" className="MainBanner2TopBoxBox">
                            <img src={appsearch} alt={`Air Duct Maintenance ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""}`} />
                            <h3>Air Duct Maintenance</h3>
                            <h2>Regular maintenance prolongs Air Duct life, ensuring safety, efficiency, and preventing costly future repairs.</h2>
                            <h4>EXPLORE THIS SERVICE</h4>
                        </a>
                        <a href="/service/Chimney-Repair" className="MainBanner2TopBoxBox">
                            <img src={appwallet} alt={`Air Duct Repair ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""}`} />
                            <h3>Air Duct Repair</h3>
                            <h2>Fix cracks, leaks, and structural issues to restore your chimney’s safety, functionality, and aesthetic appeal.</h2>
                            <h4>EXPLORE THIS SERVICE</h4>
                        </a>
                    </div>
                </div>
            </div>

            <div className="MainBanner2">
                <div className="MainBanner2TopTop">
                    <div className="MainBanner2TopTopLeft">
                        <img src={Chimney3} alt="Air Duct Cleaning BHO | Air Duct Repair BHO | Air Duct Services BHO | Air Duct Inspection BHO" />
                    </div>
                    <div className="MainBanner2TopTopRight">
                        <h4>LEAVE Air Duct TO THE PROS</h4>
                        <h5>BECAUSE WITH BHO Air Duct Cleaning, YOU GET MORE THAN JUST A SERVICE IN {TheCity && TheCity.City ? TheCity.City.toUpperCase() : ""} — YOU GET PEACE OF MIND.</h5>
                        <div className="MainBanner2TopBox">
                            <h2>REPUTATION</h2>
                            <h3>Trusted Air Duct experts since 1975 BHO. Delivering exceptional service in {TheCity.City}, {TheCity.State} and craftsmanship with decades of proven expertise.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>TRANSPARENCY</h2>
                            <h3>No hidden fees—our honest pricing covers Air Duct services any time, including holidays.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>EFFICIENCY</h2>
                            <h3>Prompt same-day and emergency Air Duct services BHO, available 365 days a year to meet your needs.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>QUALITY</h2>
                            <h3>Comprehensive chimney, from cleaning to repair, using advanced techniques and the latest equipment in {TheCity.City}, {TheCity.State}.</h3>
                        </div>
                    </div>
                </div>

                {/* Title */}
                <div className="MainBanner2TopTopBtn">
                    <a href="/book">SCHEDULE ONLINE<img src={book} alt={`Air Duct Cleaning ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""}`} /></a>
                    <a href={`tel:866-645-9692`}>CALL NOW 866-645-9692</a>
                </div>
            </div>
            <div className="MainBanner3">
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Air Duct Cleaning In {TheCity.City}</h2>
                            <h3>
                                Exceptional Air Duct Cleaning Services In {TheCity.City}, {TheCity.State} | BHO
                                <br />
                                <br />
                                Air Duct cleaning services BHO. Maintaining a clean Air Duct is essential for the safety and efficiency of your fireplace or stove. Over time, soot, creosote, and other debris accumulate inside your chimney, posing fire hazards and reducing efficiency. Our expert technicians are committed to ensuring your Air Duct remains clean and safe for use.
                                <br />
                                <br />
                            </h3>
                            <a href="/service/Chimney-Cleaning">Learn More</a>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={Chimney1} alt={`Air Duct Cleaning ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""}`} />
                        </div>
                    </div>
                    <div className="MainBanner2BottomBox">

                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Air Duct Inspection In {TheCity.City}</h2>
                            <h3>
                                Exceptional Air Duct Inspection In {TheCity.City}, {TheCity.State} | BHO
                                <br />
                                <br />
                                Air Duct inspection services BHO. Regular Air Duct inspections are crucial for the safety and efficiency of your fireplace or stove. Over time, issues such as soot buildup, creosote accumulation, and structural concernsn pose significant fire hazards and reduce efficiency. Our expert technicians are dedicated to thoroughly inspecting your Air Duct to ensure it remains safe and in optimal condition for use.
                                <br />
                                <br />
                            </h3>
                            <a href="/service/Chimney-Inspection">Learn More</a>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={Chimney2} alt={`Air Duct Cleaning ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""}`} />
                        </div>
                    </div>
                </div>
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Air Duct Repair In {TheCity.City}</h2>
                            <h3>
                                Professional Air Duct Repair Services BHO
                                <br />
                                <br />
                                At BHO Air Duct Cleaning, we offer expert Air Duct repair services to homeowners. A well-maintained Air Duct is essential for the safety and efficiency of your fireplace or stove. Over time, your chimneyn suffer from wear and tear, weather damage, and other issues thatn compromise its integrity. Our skilled technicians are here to provide comprehensive Air Duct repairs, ensuring your Air Duct remains safe and functional.
                                <br />
                                <br />
                            </h3>
                            <a href="/service/Chimney-Repair">Learn More</a>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={Chimney3}alt={`Air Duct Cleaning ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""}`} />
                        </div>
                    </div>
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Air Duct Maintenance In {TheCity.City}</h2>
                            <h3>
                                Top-Notch Air Duct Maintenance Services BHO
                                <br />
                                <br />
                                At BHO Air Duct Cleaning, we specialize in providing comprehensive Air Duct maintenance services to homeowners. Regular Air Duct maintenance is essential for the safety, efficiency, and longevity of your fireplace or stove. Our skilled technicians are dedicated to keeping your Air Duct in optimal condition, ensuring that it operates safely and efficiently year-round.
                                <br />
                                <br />
                            </h3>
                            <a href="/service/Chimney-Maintenance">Learn More</a>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={Chimney4} alt={`Air Duct Cleaning ${TheCity && TheCity.City ? TheCity.City + ", " + TheCity.StateID : ""}`} />
                        </div>


                    </div>
                </div>
            </div>
            <div className="MainBanner5">
                <div className="MainBanner5Left">
                    <h1>How to get in touch with BHO Air Duct Cleaning Services In {TheCity.City} ?</h1>
                    <div className="MainBanner5LeftBox">
                        <h2>1</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Reach Out to Us</h3>
                            <h4>Contact our expert team by phone or visit our website to schedule your Air Duct Cleaning service.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>2</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Provide Your Details</h3>
                            <h4>Share your address and any specific details about your home that could impact the service, ensuring our team is well-prepared.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>3</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Help Us Understand Your Situation</h3>
                            <h4>Send us photos of your Air Duct to give us a clear view of its current condition, allowing us to provide an accurate service estimate.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>4</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Schedule Your Service</h3>
                            <h4>Discuss the urgency of your needs, and we’ll arrange a convenient date and time for our team to visit.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>5</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Receive a Customized Quote</h3>
                            <h4>Our local service provider will assess your requirements and provide a quote based on our standard pricing.</h4>
                        </div>
                    </div>
                    <a href={`tel:866-645-9692`}>CALL NOW 866-645-9692</a>
                    <a href="/book">SCHEDULE ONLINE</a>
                </div>
                <div className="MainBanner5Right">
                    <img src={Chimney2} alt="Air Duct Cleaning Near Me BHO" />
                    <div className="MainBanner5RightBubble1"></div>
                    <div className="MainBanner5RightBubble2"></div>
                </div>

            </div>
            <div className="MainBanner6">
                <div className="MainBanner6Left">
                    <h2>GOT QUESTIONS? WE'VE GOT ANSWERS.</h2>
                    <h3>Explore Our FAQs for Your Air Duct Needs or Watch Our Latest Videos.</h3>
                    <a href="/faq">READ OUR Air Duct FAQS</a>
                </div>
                <div className="MainBanner6Right">
                    <img src={bgnobgs} alt="Air Duct Cleaning BHO | Air Duct Repair BHO | Air Duct Services BHO | Air Duct Inspection BHO" />
                </div>
            </div>
            <div className="MainBanner7">
                <h1>Real Testimonials from Our Valued Clients</h1>
                <div className="MainBanner7Maein">
                    <div className="MainBanner7Box">
                        <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                        <h2>I had an excellent experience with Air Duct Cleaning. The team was punctual, professional, and very thorough in their work. They not only cleaned my Air Duct but also provided valuable advice on maintaining it. I feel much safer knowing my Air Duct is in good hands!</h2>
                        <h4>John M.</h4>
                    </div>
                    <div className="MainBanner7Box">
                        <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                        <h2>Air Duct Cleaning exceeded my expectations. Their technicians were friendly and knowledgeable, explaining each step of the inspection and cleaning process. They even found and fixed a small issue I wasn't aware of. I highly recommend their services to anyone in the area.</h2>
                        <h4>Sarah T.</h4>
                    </div>
                    <div className="MainBanner7Box">
                        <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                        { }
                        <h2>I’ve used Air Duct Cleaning for both cleaning and repairs, and I’m always impressed with their level of service. They use top-quality materials and are very detail-oriented. My Air Duct has never been in better shape, and I appreciate their commitment to safety and customer satisfaction.</h2>
                        <h4>David R.</h4>
                    </div>
                </div>
                <a href={`/reviews/local`}>MORE FROM OUR CUSTOMERS &gt;</a>
            </div>
            <div className="MainBanner4">
                <div className="MainBanner4Right">
                    <h1>OUR OUR Air Duct SERVICES IN {TheCity && TheCity.City ? TheCity.City.toUpperCase() : ""}</h1>
                    <h2>At BHO Air Duct Cleaning, we provide a full range of Air Duct services, including cleaning, inspection, repair, and maintenance. Our certified technicians are dedicated to ensuring your Air Duct is safe, efficient, and in optimal condition. We use only high-quality materials and cutting-edge equipment to deliver outstanding results, enhancing both the safety and comfort of your home while giving you peace of mind.</h2>
                    <div className="MainBafnner4RightBenefits">
                        {AreaData.map((A, index) =>
                            <a key={index} href={A.Path} className="Header1BoxMenuBox">
                                {A.TitleShortVery}
                            </a>
                        )}
                    </div>
                </div>
            </div>

            <div className="BlogsListFaq">
                <div className="BlogsListFaqHead">
                    <h1>Frequently asked questions</h1>
                    <a href="/faq">View All</a>
                </div>
                <div className="BlogsListFaqList">
                    {FaqList.slice(getNumberFaq, (getNumberFaq + 5)).map((A, index) =>
                        <article key={index} className="FaqListBox">
                            <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                <h1>{A.question}</h1>
                                {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                <h3><span> {A.category}</span></h3>
                            </a>
                        </article>

                    )}
                </div>
            </div>
            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                    <a href="/blog">View All</a>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>

                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                            <article>
                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>
                        </a>
                    )}
                </div>
            </div>

        </div >
    )
}

