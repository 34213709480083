import React, { useState } from "react";
import { Helmet } from "react-helmet";
import './HomePageGoogle.scss';
import { useLocation, useNavigate } from "react-router-dom";
import Faq from "react-faq-component";
// images
import AreaData from '../../Components/ServicesPage/AreaData'
import LocationReviewsData from '../LocationRivews/LocationReviewsData'
import BookAnAppointment from "../BookAnAppointment/BookAnAppointment";
import emailjs from 'emailjs-com';

export default function HomePageGoogle({ CitiePhone, CitiesData }) {
    const location = useLocation()
    const navigate = useNavigate()
    const [FormSubmiting, setFormSubmiting] = useState(false);
    const url = new URL(window.location.href);
    function sendEmail(e) {
        // setFormSubmiting(true)
        setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_vdkxpnd', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                setFormSubmiting(false)
                navigate(`/thankscontact?url=${location.pathname + location.search}`)
            }, (error) => {
                console.log(error.text);
            });
    }

    // Faqs
    const data = {
        title: "",
        rows: [
            {
                title: "Why choose BHO Air Duct Cleaning?",
                content: "With 50 years of experience, we ensure cleaner air, better HVAC efficiency, and a safer home environment."
            },
            {
                title: "What is BHO stands for?",
                content: "BHO stands for Bob and Henry Oliver, the founders who built the company on a commitment to quality air duct and dryer vent cleaning. We've been serving homes for decades with trusted service."
            },
            {
                title: "What services does BHO Air Duct Cleaning offer?",
                content: "We specialize in air duct and dryer vent cleaning, along with free inspections to identify any issues before cleaning."
            },
            {
                title: "How can BHO improve my home?",
                content: "Our services enhance air quality, reduce allergens, and optimize system performance, leading to a healthier home."
            },
            {
                title: "Why is regular air duct cleaning necessary?",
                content: "It eliminates dust buildup, reduces allergens, and improves system efficiency, ensuring better air circulation and longer system life."
            },
            {
                title: "What are the service prices?",
                content: `Air duct cleaning is $99, dryer vent cleaning is $79, and inspections are free with any cleaning service. Call us <a href='tel:${CitiePhone}'>${CitiePhone}</a> or fill out the <a href='/book${location.search}'}>online form</a> to schedule your visit.`
            },
        ]
    };
    const styles = {
        bgColor: '#eceef6',
        titleTextColor: 'white',
        titleTextSize: '18px',
        rowTitleColor: '#2a1768',
        rowTitleTextSize: '21px',
        rowContentColor: '#242424',
        rowContentTextSize: '16px',
        // rowContentTextWeight: '600',
        rowContentPaddingTop: '10px',
        rowContentPaddingBottom: '10px',
        rowContentPaddingLeft: '5px',
        rowContentPaddingRight: '5px',
        arrowColor: "#2a1768",
        //transitionDuration: "1s",
        // timingFunc: "ease"
    };
    const config = {
        // animate: true,
        // arrowIcon: "V",
        // tabFocus: true
        openOnload: 0,
    };

    return (
        <div className="HomePageGoogle" id="homepage">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>BHO Air Duct Cleaning | Expert Air Duct Cleaning, Inspection & Services | {CitiePhone}</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Air Duct Cleaning | Expert Air Duct Cleaning, Inspection & Services | {CitiePhone}" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Air Duct Cleaning | Expert Air Duct Cleaning, Inspection & Services | {CitiePhone}" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.bhoairductcleaning.com/" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Air Duct Cleaning provides top-quality Air Duct cleaning, inspections, and repairs to keep your home safe and efficient. Trust our experienced professionals for all your Air Duct service needs." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Air Duct Cleaning provides top-quality Air Duct cleaning, inspections, and repairs to keep your home safe and efficient. Trust our experienced professionals for all your Air Duct service needs." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Air Duct Cleaning" data-react-helmet="true" />
                <meta name="keywords" content="Air Duct Cleaning, Air Duct Inspection, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct services, Air Duct service, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Insulation, Air Duct Installation, Air Duct Liner Repair, Air Duct Pointing, Air Duct Rainp Installation, Air Duct Repair, Air Duct Crown Repair, Air Duct Restoration, Air Duct Flue Installation, Air Duct Flue Repair, ​Air Duct Construction, Air Duct Vent Installation, Air Duct Flashing, Flexible Air Duct Liner Installation, Air Duct Damper Repair, Chimneyp Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rainp Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplacep" data-react-helmet="true" />
            </Helmet>
            {/* MainBanner */}
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>The Air Duct Experts You've Trusted for Over 50 Years In {CitiesData ? CitiesData.Full : "Your Area"}</h1>
                    {/* <h2>Servicing <b>{CitiesData ? CitiesData.City.toUpperCase() : "Your Area"}</b> And Surrounding Areas - Air Ducts & Dryer Vents Cleaning, Services And Repairs Near Me</h2> */}
                    <h2>Servicing <b>{CitiesData ? CitiesData.City.toUpperCase() : "Your Area"}</b> And Surrounding Areas • <span>{url.searchParams.get('keyword') ? url.searchParams.get('keyword').includes('near') ? url.searchParams.get('keyword').replace('near me', '•') : url.searchParams.get('keyword') + "," : ""}</span> Air Ducts & Dryer Vents Cleaning, Services And Repair Near Me</h2>
                    {/* <h2>Air Duct Services, Repairs And Cleaning Near Me</h2> */}
                    {/* <h2>Air Duct Cleaning Near Me</h2> */}
                    <h3><img src={require(`../../Images/checklist.png`)} alt="Locally Owned & Operated BHO Air Duct Cleaning Repair" />Locally Owned & Operated</h3>
                    <h3><img src={require(`../../Images/checklist.png`)} alt="Licensed | Bonded | Insured BHO Air Duct Cleaning Repair" />Certified | Licensed | Insured</h3>
                    <h3><img src={require(`../../Images/checklist.png`)} alt="Satisfaction Guaranteed BHO Air Duct Cleaning Repair" />Satisfaction Guaranteed</h3>
                    <div className="MainBannerLeftButtons">
                        <a href={`tel:${CitiePhone}`}>Call Us Now!</a>
                        <a href={`tel:${CitiePhone}`}>{CitiePhone}</a>
                        <a onClick={() => navigate(`/book${location.search}`)}>SCHEDULE ONLINE<img src={require(`../../Images/book.png`)} alt="Air Duct Cleaning Near Me BHO" /></a>
                    </div>
                </div>
                <div className="MainBannerRight">
                    <img src={require(`../../ImagesLogos/Banner1Logo.png`)} alt="Air Duct Cleaning BHO | Air Duct Repair BHO | Air Duct Services BHO | Air Duct Inspection BHO" />
                    <div className="MainBannerRightBubble1"></div>
                    <div className="MainBannerRightBubble2"></div>
                </div>
            </div>
            {/* BookAnAppointmentMainComponentEmergery */}
            <div className="BookAnAppointmentMainComponentEmergery">
                <div className="BookAnAppointmentMainComponentRates">
                    <span className="GoogleButt">
                        <h6>5 STAR - Based On 1,836 Reviews</h6>
                    </span>
                    <div className="BookAnAppointmentMainComponentRatesImages">
                        <img src={require(`../../Images/YELP.png`)} alt="BHO Air Duct Cleaning Cleaning Yelp Review" />
                        <img src={require(`../../Images/Angie.png`)} alt="BHO Air Duct Cleaning Cleaning Angie Review" />
                        <img src={require(`../../Images/HomeAdvisor.png`)} alt="BHO Air Duct Cleaning Cleaning HomeAdvisor Review" />
                        <img src={require(`../../Images/BBB.png`)} alt="BHO Air Duct Cleaning Cleaning BBB Review" />
                        <img src={require(`../../Images/Google.png`)} alt="BHO Air Duct Cleaning Cleaning Google Review" />
                        <img src={require(`../../Images/Porch.png`)} alt="BHO Air Duct Cleaning Cleaning Porch Review" />
                        <img src={require(`../../Images/Trust.png`)} alt="BHO Air Duct Cleaning Cleaning Trust Review" />
                    </div>
                    <h1>Customer Testimonials</h1>
                    <h2>⭐⭐⭐⭐⭐</h2>
                    <h3>
                        I’ve relied on BHO Air Duct Cleaning for both Air Duct & Dryer Vent Cleaning and repair services, and they consistently exceed my expectations. The team uses high-quality materials and pays great attention to detail. My air ducts are in the best condition they’ve ever been, thanks to their meticulous work. I truly value their dedication to safety and customer satisfaction, which keeps me coming back for all my duct needs.
                        <br />
                        <br />
                        <a onClick={() => window.location.hash = 'reviews'}>MORE FROM OUR CUSTOMERS &gt;</a>
                    </h3>

                </div>

                <div className="BookAnAppointmentMainComponentEmergeryBox">
                    <a href={`tel:${CitiePhone}`}>For emergency service Call: {CitiePhone}</a>
                </div>
            </div>
            {/* MainBanner2Top2 */}
            <div className="MainBanner2Top2" id="ourservices">
                <div className="MainBanner2TopBox">
                    <div className="MainBanner2TopBoxBack">
                        {/*  */}
                    </div>
                    <div className="MainBanner2TopBoxMain">
                        <h2>OUR AIR DUCT SERVICES</h2>
                        <div className="MainBanner2TopBoxMenu">
                            <a className="MainBanner2TopBoxBox">
                                <img src={require(`../../Images/aircleaning.png`)} alt="Air Duct Cleaning" />
                                <h3>Air Duct Cleaning</h3>
                                <h2>Experience cleaner, fresher air with our thorough air duct cleaning services, ensuring a healthier home environment.</h2>
                            </a>
                            <a className="MainBanner2TopBoxBox">
                                <img src={require(`../../Images/airdryer.png`)} alt="Air Duct Cleaning" />
                                <h3>Dryer Vent Cleaning</h3>
                                <h2>Prevent fire hazards and improve dryer efficiency with our professional dryer vent cleaning services for safer homes.</h2>
                            </a>
                            <a className="MainBanner2TopBoxBox">
                                <img src={require(`../../Images/airrepair.png`)} alt="Air Duct Cleaning" />
                                <h3>Air Duct & Dryer Repair</h3>
                                <h2>Our expert air duct repairs restore efficiency and performance, eliminating leaks for better air quality and energy savings.</h2>
                            </a>
                            <a className="MainBanner2TopBoxBox">
                                <img src={require(`../../Images/airinspection.png`)} alt="Air Duct Cleaning" />
                                <h3>Air Duct & Dryer Inspection</h3>
                                <h2>Our detailed air duct inspections identify issues early, ensuring optimal system performance and indoor air quality.</h2>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* MainBanner2 */}
            <div className="MainBanner2" id="about">
                <div className="MainBanner2TopTop">
                    <div className="MainBanner2TopTopLeft">
                        <img src={require(`../../Images/PhotoImg3.jpg`)} alt="Air Duct Cleaning BHO | Air Duct Repair BHO | Air Duct Services BHO | Air Duct Inspection BHO" />
                    </div>
                    <div className="MainBanner2TopTopRight">
                        <h4>LEAVE AIR DUCT TO THE PROS</h4>
                        <h5>BECAUSE WITH BHO AIR DUCT CLEANING, YOU GET MORE THAN JUST A SERVICE—YOU GET PEACE OF MIND.</h5>
                        <div className="MainBanner2TopBox">
                            <h2>REPUTATION</h2>
                            <h3>Trusted Air Duct experts since 1975 BHO Air Duct Delivering exceptional service and craftsmanship with decades of proven expertise.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>TRANSPARENCY</h2>
                            <h3>No hidden fees—our honest pricing covers Air Duct services any time, including holidays.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>EFFICIENCY</h2>
                            <h3>Prompt same-day and emergency Air Duct services, available 365 days a year to meet your needs.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>QUALITY</h2>
                            <h3>Comprehensive chimney, from cleaning to repair, using advanced techniques and the latest equipment.</h3>
                        </div>
                    </div>
                </div>

                {/* Title */}
                <div className="MainBanner2TopTopBtn">
                    <div className="MainBanner2TopTopBtnLeft">
                        <img src={require(`../../Images/tag.png`)} alt="" />
                        <span>
                            <h1>$69 Air Duct Inspection Service</h1>
                            <h2>Air Duct cleaning in <b>{CitiesData ? CitiesData.City.toUpperCase() : "Your Area"}</b> is not merely about appearances; it's a crucial step in safeguarding your home's air quality and overall safety. Regular cleaning reduces the buildup of harmful contaminants and ensures that your Air Duct operates efficiently, minimizing potential fire hazards.</h2>
                        </span>
                    </div>
                    <div className="MainBanner2TopTopBtnRight">
                        <a onClick={() => navigate(`/book${location.search}`)}>SCHEDULE ONLINE<img src={require(`../../Images/book.png`)} alt="Air Duct Cleaning BHO | Air Duct Repair BHO | Air Duct Services BHO | Air Duct Inspection BHO" /></a>
                        <a href={`tel:${CitiePhone}`}>CALL NOW {CitiePhone}</a>
                    </div>
                </div>
            </div>
            {/* BookAnAppointment */}
            <BookAnAppointment CitiesData={CitiesData} CitiePhone={CitiePhone} />
            {/* MainBanner3 */}
            <div className="MainBanner3">
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Air Duct Cleaning</h2>
                            <h3>
                                Exceptional Air Duct Cleaning Services
                                <br />
                                <br />
                                Regular air duct cleaning is essential to maintaining a healthy indoor environment. Over time, dust, allergens, and debris can accumulate in your air ducts, leading to poor air quality and affecting the efficiency of your HVAC system. Our professional air duct cleaning services ensure your ducts are thoroughly cleaned, removing contaminants and improving airflow, helping you breathe easier and keeping your home comfortable.
                                <br />
                                <br />
                            </h3>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={require(`../../Images/PhotoImg1.jpg`)} alt="Air Duct Cleaning BHO" />
                        </div>
                    </div>
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Dryer Vent Cleaning</h2>
                            <h3>
                                Professional Dryer Vent Cleaning Services
                                <br />
                                <br />
                                Keeping your dryer vent clean is crucial for safety and performance. Over time, lint and debris can build up, restricting airflow and creating a serious fire hazard. Our professional dryer vent cleaning services remove built-up lint and obstructions, allowing your dryer to operate efficiently. Regular cleaning not only reduces the risk of fire but also improves drying times, reduces energy consumption, and extends the life of your dryer.
                                <br />
                                <br />
                            </h3>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={require(`../../Images/PhotoImg4.jpg`)} alt="Air Duct Repair BHO" />
                        </div>
                    </div>
                </div>
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">

                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Air Duct Repair</h2>
                            <h3>
                                Exceptional Air Duct Repair
                                <br />
                                <br />
                                Damaged air ducts can lead to inefficient HVAC performance and higher energy bills. Whether it's leaks, gaps, or other issues, our expert technicians specialize in identifying and repairing air duct problems, ensuring optimal airflow throughout your home. With our air duct repair services, you'll benefit from better air quality, consistent temperatures, and lower utility costs, making your home more energy-efficient and comfortable.
                                <br />
                                <br />
                            </h3>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={require(`../../Images/PhotoImg5.png`)} alt="Air Duct Inspection BHO" />
                        </div>
                    </div>
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Air Duct & Dryer Inspection</h2>
                            <h3>
                                Top-Notch Air Duct & Dryer Inspection Services
                                <br />
                                <br />
                                Regular air duct and dryer inspections are vital for the efficiency and safety of your HVAC system. Our expert technicians conduct thorough inspections to identify any issues, such as blockages, leaks, or damage, that could impact airflow and indoor air quality. By addressing problems early, we help maintain the efficiency of your system, ensuring it operates smoothly. Our inspections give you peace of mind knowing your air ducts are in top condition.
                                <br />
                                <br />
                            </h3>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={require(`../../Images/PhotoImg2.jpg`)} alt="Air Duct Maintenance BHO" />
                        </div>


                    </div>
                </div>
            </div>
            {/* MainBanner5 */}
            <div className="MainBanner5">
                <div className="MainBanner5Left">
                    <h1>HOW TO GET IN TOUCH WITH BHO AIR DUCT CLEANING SERVICES?</h1>
                    <div className="MainBanner5LeftBox">
                        <h2>1</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Reach Out to Us</h3>
                            <h4>Contact our expert team by phone or visit our <b onClick={() => navigate(`/book${location.search}`)}>book an appointment page</b> to schedule your Air Duct Cleaning service.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>2</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Provide Your Details</h3>
                            <h4>Share your address and any specific details about your home that could impact the service, ensuring our team is well-prepared.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>3</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Schedule Your Service</h3>
                            <h4>Discuss the urgency of your needs, and we’ll arrange a convenient date and time for our team to visit.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>4</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Receive a Customized Quote</h3>
                            <h4>Our local service technician will assess your requirements and provide a quote based on your chimney.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftAbutton">
                        <a href={`tel:${CitiePhone}`}>CALL NOW {CitiePhone}</a>
                        <a onClick={() => navigate(`/book${location.search}`)}>SCHEDULE ONLINE</a>
                    </div>
                </div>
                <div className="MainBanner5Right">
                    <img src={require(`../../ImagesLogos/Image2Logo.png`)} alt="Air Duct Cleaning Near Me BHO" />
                    <div className="MainBanner5RightBubble1"></div>
                    <div className="MainBanner5RightBubble2"></div>
                </div>

            </div>
            {/* MainBanner6 */}
            <div className="MainBanner6">
                <div className="MainBanner6Left">
                    <h2>GOT QUESTIONS? WE'VE GOT ANSWERS.</h2>
                    <h3>Feel Free To Reach Out To Us By Phone. For The Fastest Response, We Are Available By Phone 24/7 And Ready To Provide You With A Complimentary Consultation.</h3>
                    <a className="MainBanner6LeftContact" onClick={() => window.location.hash = 'contact'}>CONTACT US</a>
                    <div className="MainBanner4FaqMore">
                        <Faq
                            data={data}
                            styles={styles}
                            config={config}
                        />
                    </div>
                </div>
                <div className="MainBanner6Right">
                    <img src={require(`../../Images/bgnobgs.png`)} alt="Air Duct Cleaning BHO | Air Duct Repair BHO | Air Duct Services BHO | Air Duct Inspection BHO" />
                </div>
            </div>
            {/* MainBanner7 */}
            <div className="MainBanner7" id='reviews'>
                <h1>Real Testimonials from Our Valued Clients</h1>
                <div className="LocationReviewsLsit">
                    <div className="LocationReviewsLsitUnder">
                        {LocationReviewsData.slice(0, 36).map((A, index) =>
                            <div key={index} className="LocationReviewsBox">
                                <div className="LocationReviewsBoxHeader">
                                    <div className="LocationReviewsBoxHeaderBox">
                                        <h1>{A.service_type}</h1>
                                        <h2>BHO Air Duct Cleaning</h2>
                                    </div>
                                    <div className="LocationReviewsBoxHeaderBox">
                                        <h1>⭐⭐⭐⭐⭐</h1>
                                        <h2>{A.date}</h2>
                                    </div>
                                </div>

                                <div className="LocationReviewsBoxLeft">
                                    {/* <h3>Open Now 24/7 • Top Rated • Same Day Service</h3> */}
                                    <h1>⭐ ⭐ ⭐ ⭐ ⭐</h1>
                                    <h2>Rated 5 By {A.customer_name}</h2>
                                    <h4>{A.review}</h4>
                                    <div className="LocationReviewsBoxLeftBox">
                                        <div className="LocationReviewsBoxLeftBoxBox">
                                            <h5>Service Provided:</h5>
                                            <h6>{A.service_type}</h6>
                                        </div>
                                        <div className="LocationReviewsBoxLeftBoxBox">
                                            <h5>Technician</h5>
                                            <h6>{A.tech_name}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="LocationReviewsLsitShodowR"></div>
                </div>
            </div>
            {/* MainBanner8 */}
            <div className="MainBanner8" id="contact">
                <div className="MainBanner8Left">
                    <h1>Get In Touch</h1>
                    <h2>Is There Anything Else You'd Like To Know?</h2>
                    <h3>We Are Available Via Phone Or By This Form. However If You Would Like A Quick Response We Are By Our Phones 24/7 To Give You A Phone Consultation Free Of Charge.</h3>
                    <a href={`tel:${CitiePhone}`}>Click To Call Us Now!</a>
                    <a onClick={() => navigate(`/book${location.search}`)}>SCHEDULE ONLINE<img src={require(`../../Images/book.png`)} alt="Air Duct Cleaning Near Me BHO" /></a>
                </div>
                <div className="MainBanner8Right">

                    <div className="ContactFirstBody">
                        <form className="contact-form" onSubmit={sendEmail}>
                            <h4>Don't Hesitate To Reach Out!</h4>
                            <h5>If you need help with a new service or have questions about an existing one, please reach out via phone or by this form. Our team is here to assist you!</h5>
                            <h2>Full Name</h2>
                            <input type="text" name="Full_Name" required />
                            <h2>Address</h2>
                            <input type="text" name="Country" required />
                            <div className='ContactFirsthhh'>
                                <div className="ContactFirsthhhBox">
                                    <h2>Phone</h2>
                                    <input type="tel" name="Phone" required />
                                </div>

                            </div>
                            <h2>Massage</h2>
                            <textarea className="Message" name="Message" id="" cols="30" rows="10" required></textarea>
                            {
                                FormSubmiting ?
                                    <button class="buttonload">
                                        <i class="fa fa-refresh fa-spin"></i>Loading
                                        {/* <input type="submit" value="SEND" /> */}
                                    </button>
                                    :
                                    null
                            }
                            {
                                !FormSubmiting ?
                                    <span>
                                        <input type="submit" value="CLICK TO SEND" />
                                        <input type="hidden" name="Company_From" defaultValue={`BHO Air Duct Cleaning | BHO`} />
                                        <input type="hidden" name="Company" defaultValue={`BHO Air Duct Cleaning | BHO`} />
                                    </span>
                                    :
                                    null
                            }


                        </form>
                    </div>
                </div>
            </div>
            {/* MainBanner4 */}
            <div className="MainBanner4">
                <div className="MainBanner4Right">
                    <h1>OUR AIR DUCT SERVICES</h1>
                    <h2>At BHO Air Duct Cleaning Services, we offer a comprehensive range of air duct services, including cleaning, inspection, repair, and maintenance. Our certified technicians are committed to ensuring your air ducts are safe, efficient, and in peak condition. Using high-quality materials and state-of-the-art equipment, we deliver exceptional results, enhancing the safety and comfort of your home and providing peace of mind.</h2>
                    <div className="MainBafnner4RightBenefits">
                        {AreaData.map((A, index) =>
                            <a key={index} href={`${A.Path}${location.search}`} className="Header1BoxMenuBox">
                                {A.TitleShortVery}
                            </a>
                        )}
                    </div>
                </div>
            </div>
            {/* HomePageGoogleFooter */}
            <div className="HomePageGoogleFooter">
                <div className="HomePageGoogleFooterTop">
                    <div className="HomePageGoogleFooterTopTitle">
                        <img src={require(`../../Images/Music.png`)} />
                        <h1>Air So Clean, Ducts Unseen<br />BHO’s On The Scene!</h1>
                        <img src={require(`../../Images/Music.png`)} />
                    </div>
                    <div className="HomePageGoogleFooterTopImg">
                        <img src={require(`../../ImagesLogos/Image1Logo.png`)} />
                    </div>
                </div>
                <div className="HomePageGoogleFooterBottom">
                    <div className="HomePageGoogleFooterBottomTop">
                        <img src={require(`../../ImagesLogos/Banner2Logo.png`)} />
                        <p>Copyright 1975-2024 BHO Air Duct Cleaning. All rights reserved. All available services, hours of operations, pricing structure, and guarantees may vary by location.</p>
                    </div>
                    <div className="HomePageGoogleFooterBottomBottom">
                        <span>
                            <img src={require(`../../Images/tag.png`)} alt="" />
                            <a>www.bhoairductcleaning.com</a>
                        </span>
                        <span>
                            <img src={require(`../../Images/tag.png`)} alt="" />
                            <a href={`tel:${CitiePhone}`}>{CitiePhone}</a>
                        </span>
                        <span>
                            <img src={require(`../../Images/tag.png`)} alt="" />
                            <a>info@bhoairductcleaning.com</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

