import React, { useEffect } from "react";
import './ServicesPage.scss';
import $ from 'jquery';
import { useState } from "react";
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"

// images


import Chimney1 from '../../Images/PhotoImg1.jpg'
import Chimney2 from '../../Images/PhotoImg2.jpg'
import Chimney3 from '../../Images/PhotoImg3.jpg'
import Chimney4 from '../../Images/PhotoImg4.jpg'
import Chimney5 from '../../Images/PhotoImg3.jpg'
import appcalendar from '../../Images/appcalendar.png'
import appdropshipping from '../../Images/appdropshipping.png'
import appsearch from '../../Images/appsearch.png'
import appwallet from '../../Images/appwallet.png'
import book from '../../Images/book.png'
import bgnobgs from '../../Images/bgnobgs.png'
import HomeAdvisor from '../../Images/HomeAdvisor.png'
import BBB from '../../Images/BBB.png'
import Angie from '../../Images/Angie.png'
import YELP from '../../Images/YELP.png'
import AreaData from './AreaData'
import Google from '../../Images/Google.png'
import Trust from '../../Images/Trust.png'
import Porch from '../../Images/Porch.png'
import { useLocation } from "react-router-dom";
import parse from 'html-react-parser'

export default function ServicesPage({ CitiePhone, navigateTo, FaqList, Blogs }) {
    const [Content, setContent] = useState(null);

    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }

    const getSpecificNumberFaq = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberFaq()
        }
    }
    const [getNumberFaq, setgetNumberFaq] = useState(getSpecificNumberFaq());
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());

    const location = useLocation().pathname

    useEffect(() => {
        setContent(AreaData.find(a => a.Path == location));
        console.log(Content);
    }, []);

    return (
        <div className="ServicesPage">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>{Content ? Content.Des + " " + "| 866-645-9692" : "BHO Air Duct Cleaning & Cleaning 866-645-9692 | Best Air Duct Cleaning"}</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content={`${Content ? Content.Des + " " + "| 866-645-9692" : "BHO Air Duct Cleaning & Cleaning 866-645-9692 | Best Air Duct Cleaning"}`} data-react-helmet="true" />
                <meta name="og:title" property="og:title" content={`${Content ? Content.Des + " " + "| 866-645-9692" : "BHO Air Duct Cleaning & Cleaning 866-645-9692 | Best Air Duct Cleaning"}`} data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.bhoairductcleaning.com/" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content={`${Content ? Content.Full + " " + "| 866-645-9692" : "BHO Air Duct Cleaning & Cleaning 866-645-9692 | Best Air Duct Cleaning"}`} data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content={`${Content ? Content.Full + " " + "| 866-645-9692" : "BHO Air Duct Cleaning & Cleaning 866-645-9692 | Best Air Duct Cleaning"}`} data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="BHO Air Duct Cleaning" data-react-helmet="true" />
                <meta name="keywords" content="Air Duct Cleaning, Air Duct Inspection, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct services, Air Duct service, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Insulation, Air Duct Installation, Air Duct Liner Repair, Air Duct Pointing, Air Duct Rainp Installation, Air Duct Repair, Air Duct Crown Repair, Air Duct Restoration, Air Duct Flue Installation, Air Duct Flue Repair, ​Air Duct Construction, Air Duct Vent Installation, Air Duct Flashing, Flexible Air Duct Liner Installation, Air Duct Damper Repair, Chimneyp Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rainp Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplacep" data-react-helmet="true" />
            </Helmet>
            {/* MainBanner */}
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>{Content ? Content.Des : "BHO Air Duct Cleaning"}</h1>
                    <h2>{Content ? Content.Full : "Air Duct Service Near Melifornia"}</h2>
                    <a href={`tel:${CitiePhone}`}>Call Us Now!</a>
                    <a href="/book">SCHEDULE ONLINE<img src={book} alt={Content ? Content.Title : "BHO Air Duct Cleaning"} /></a>
                </div>
                <div className="MainBannerRight">
                    <img src={bgnobgs} alt={Content ? Content.Title : "BHO Air Duct Cleaning"} />
                    <div className="MainBannerRightBubble1"></div>
                    <div className="MainBannerRightBubble2"></div>
                </div>
            </div>
            <div className="BookAnAppointmentMainComponentEmergery">

                <div className="BookAnAppointmentMainComponentRates">
                    <div className="BookAnAppointmentMainComponentRatesImages">
                        <img src={YELP} alt={`BHO Air Duct Cleaning Cleaning | ${Content ? Content.Title : ""} Yelp Review`} />
                        <img src={Angie} alt={`BHO Air Duct Cleaning Cleaning | ${Content ? Content.Title : ""} Angie Review`} />
                        <img src={HomeAdvisor} alt={`BHO Air Duct Cleaning Cleaning | ${Content ? Content.Title : ""} HomeAdvisor Review`} />
                        <img src={BBB} alt={`BHO Air Duct Cleaning Cleaning | ${Content ? Content.Title : ""} BBB Review`} />
                        <img src={Google} alt={`BHO Air Duct Cleaning Cleaning | ${Content ? Content.Title : ""} Google Review`} />
                        <img src={Porch} alt={`BHO Air Duct Cleaning Cleaning | ${Content ? Content.Title : ""} Porch Review`} />
                        <img src={Trust} alt={`BHO Air Duct Cleaning Cleaning | ${Content ? Content.Title : ""} Trust Review`} />
                    </div>
                    <h1>Customer Testimonials</h1>
                    <h2>⭐⭐⭐⭐⭐</h2>
                    <h3>
                        I’ve relied on BHO Air Duct Cleaning for both Air Duct & Dryer Vent Cleaning and repair services, and they consistently exceed my expectations. The team uses high-quality materials and pays great attention to detail. My air ducts are in the best condition they’ve ever been, thanks to their meticulous work. I truly value their dedication to safety and customer satisfaction, which keeps me coming back for all my duct needs. I’ll definitely use them again for {Content ? Content.TitleShortVery : "Service"}!
                        <a href={`/reviews/local`}>MORE FROM OUR CUSTOMERS &gt;</a>
                    </h3>

                </div>
                <span className="GoogleButt">
                    <h6>5 Stars - Based on 1,800 Reviews</h6>
                    <h6 className="GoogleButtBooms">
                        Within 5 mi • Within 20 mi • Open now • Residential • Top rated • Prices • Online estimates • Replacement
                    </h6>
                </span>
                <div className="BookAnAppointmentMainComponentEmergeryBox">
                    <a href={`tel:${CitiePhone}`}>For emergency service Call: {CitiePhone}</a>
                </div>
            </div>
            <div className="MainBannerMainBanner">
                <div className="MainBannerMainBannerLeft">
                    <h1>{Content ? Content.TitleShortVery : "BHO Air Duct Cleaning"}</h1>
                    <h2>{Content ? Content.TitleShort : "Air Duct Service Near Melifornia"}</h2>
                    <h3>Expert {Content ? Content.Des : "BHO Air Duct Cleaning"}, Inspection, and Repair Services in BHO – Ensuring Your Home’s Safety and Comfort All Year Round.</h3>
                </div>
                <div className="MainBannerMainBannerRight">
                    <img src={Chimney5} alt={Content ? Content.TitleShortVery : "BHO Air Duct Cleaning"} />
                </div>
            </div>
            <div className="WebSiteBanner">
                {Content ? parse(Content.Content) : null}
                <div className="WebSiteBannerCallMain">
                    <a className="WebSiteBannerCall" href="tel:951-309-7931">Click To Call Us Now!</a>
                    <a className="WebSiteBannerCall" href="/book">SCHEDULE ONLINE<img src={book} alt={Content ? Content.Title : "BHO Air Duct Cleaning"} /></a>
                </div>
            </div>
            <div className="MainBanner2Top2">
                <div className="MainBanner2TopBox">
                    <h2>OUR Air Duct SERVICES</h2>
                    <div className="MainBanner2TopBoxMenu">
                        <a href="/service/Chimney-Cleaning" className="MainBanner2TopBoxBox">
                            <img src={appcalendar} alt="Air Duct Cleaning" />
                            <h3>Air Duct Cleaning</h3>
                            <h2>Remove creosote buildup and debris for a safer, more efficient fireplace and improved indoor air quality.</h2>
                            <h4>EXPLORE THIS SERVICE</h4>
                        </a>
                        <a href="/service/Chimney-Inspection" className="MainBanner2TopBoxBox">
                            <img src={appdropshipping} alt="Air Duct Cleaning" />
                            <h3>Air Duct Inspection</h3>
                            <h2>Ensure your chimney’s structural integrity and functionality with a thorough inspection to prevent hazards and issues.</h2>
                            <h4>EXPLORE THIS SERVICE</h4>
                        </a>
                        <a href="/service/Chimney-Maintenance" className="MainBanner2TopBoxBox">
                            <img src={appsearch} alt="Air Duct Cleaning" />
                            <h3>Air Duct Maintenance</h3>
                            <h2>Regular maintenance prolongs Air Duct life, ensuring safety, efficiency, and preventing costly future repairs.</h2>
                            <h4>EXPLORE THIS SERVICE</h4>
                        </a>
                        <a href="/service/Chimney-Repair" className="MainBanner2TopBoxBox">
                            <img src={appwallet} alt="Air Duct Cleaning" />
                            <h3>Air Duct Repair</h3>
                            <h2>Fix cracks, leaks, and structural issues to restore your chimney’s safety, functionality, and aesthetic appeal.</h2>
                            <h4>EXPLORE THIS SERVICE</h4>
                        </a>
                    </div>
                </div>
            </div>

            <div className="MainBanner2">
                <div className="MainBanner2TopTop">
                    <div className="MainBanner2TopTopLeft">
                        <img src={Chimney3} alt="Air Duct Cleaning In BHO | Air Duct Repair In BHO | Air Duct Services In BHO | Air Duct Inspection" />
                    </div>
                    <div className="MainBanner2TopTopRight">
                        <h4>LEAVE Air Duct TO THE PROS</h4>
                        <h5>BECAUSE WITH BHO Air Duct Cleaning, YOU GET MORE THAN JUST A SERVICE—YOU GET PEACE OF MIND FOR {Content ? Content.Title : "SERVICES"}.</h5>
                        <div className="MainBanner2TopBox">
                            <h2>REPUTATION</h2>
                            <h3>Trusted Air Duct experts since 1975 Delivering exceptional service and craftsmanship with decades of proven expertise.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>TRANSPARENCY</h2>
                            <h3>No hidden fees—our honest pricing covers Air Duct services any time, including holidays.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>EFFICIENCY</h2>
                            <h3>Prompt same-day and emergency Air Duct services available 365 days a year to meet your needs.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>QUALITY</h2>
                            <h3>Comprehensive chimney, from {Content ? Content.Title : "SERVICES"}ÎÇ to repaÎÇir, using advanced techniques and the latest equipment.</h3>
                        </div>
                    </div>
                </div>

                {/* Title */}
                <div className="MainBanner2TopTopBtn">
                    <a href="/book">SCHEDULE ONLINE<img src={book} alt="Air Duct Cleaning In BHO | Air Duct Repair In BHO | Air Duct Services In BHO | Air Duct Inspection" /></a>
                    <a href={`tel:${CitiePhone}`}>CALL NOW {CitiePhone}</a>
                </div>
            </div>
            <div className="MainBanner3">
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Air Duct Cleaning </h2>
                            <h3>
                                Exceptional Air Duct Cleaning Services
                                <br />
                                <br />
                                Air Duct cleaning services. Maintaining a clean Air Duct is essential for the safety and efficiency of your fireplace or stove. Over time, soot, creosote, and other debris accumulate inside your chimney, posing fire hazards and reducing efficiency. Our expert technicians are committed to ensuring your Air Duct remains clean and safe for use.
                                <br />
                                <br />
                            </h3>
                            <a href="/service/Chimney-Cleaning">Learn More</a>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={Chimney1} alt="Air Duct Cleaning " />
                        </div>
                    </div>
                    <div className="MainBanner2BottomBox">

                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Air Duct Inspection </h2>
                            <h3>
                                Exceptional Air Duct Inspection Services
                                <br />
                                <br />
                                Air Duct inspection services. Regular Air Duct inspections are crucial for the safety and efficiency of your fireplace or stove. Over time, issues such as soot buildup, creosote accumulation, and structural concernsn pose significant fire hazards and reduce efficiency. Our expert technicians are dedicated to thoroughly inspecting your Air Duct to ensure it remains safe and in optimal condition for use.
                                <br />
                                <br />
                            </h3>
                            <a href="/service/Chimney-Inspection">Learn More</a>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={Chimney2} alt="Air Duct Inspection " />
                        </div>
                    </div>
                </div>
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Air Duct Repair </h2>
                            <h3>
                                Professional Air Duct Repair Services
                                <br />
                                <br />
                                At BHO Air Duct Cleaning, we offer expert Air Duct repair services to homeowners. A well-maintained Air Duct is essential for the safety and efficiency of your fireplace or stove. Over time, your chimneyn suffer from wear and tear, weather damage, and other issues thatn compromise its integrity. Our skilled technicians are here to provide comprehensive Air Duct repairs, ensuring your Air Duct remains safe and functional.
                                <br />
                                <br />
                            </h3>
                            <a href="/service/Chimney-Repair">Learn More</a>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={Chimney3} alt="Air Duct Repair " />
                        </div>
                    </div>
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Air Duct Maintenance </h2>
                            <h3>
                                Top-Notch Air Duct Maintenance Services
                                <br />
                                <br />
                                At BHO Air Duct Cleaning, we specialize in providing comprehensive Air Duct maintenance services to homeowners. Regular Air Duct maintenance is essential for the safety, efficiency, and longevity of your fireplace or stove. Our skilled technicians are dedicated to keeping your Air Duct in optimal condition, ensuring that it operates safely and efficiently year-round.
                                <br />
                                <br />
                            </h3>
                            <a href="/service/Chimney-Maintenance">Learn More</a>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={Chimney4} alt="Air Duct Maintenance " />
                        </div>


                    </div>
                </div>
            </div>
            <div className="MainBanner5">
                <div className="MainBanner5Left">
                    <h1>How to get in touch with {Content ? Content.Title : "SERVICES"} ?</h1>
                    <div className="MainBanner5LeftBox">
                        <h2>1</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Reach Out to Us</h3>
                            <h4>Contact our expert team by phone or visit our website to schedule your BHO Air Duct Cleaning service.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>2</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Provide Your Details</h3>
                            <h4>Share your address and any specific details about your home that could impact the service, ensuring our team is well-prepared.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>3</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Help Us Understand Your Situation</h3>
                            <h4>Send us photos of your Air Duct to give us a clear view of its current condition, allowing us to provide an accurate service estimate.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>4</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Schedule Your Service</h3>
                            <h4>Discuss the urgency of your needs, and we’ll arrange a convenient date and time for our team to visit.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>5</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Receive a Customized Quote</h3>
                            <h4>Our local service provider will assess your requirements and provide a quote based on our standard pricing.</h4>
                        </div>
                    </div>
                    <a href={`tel:${CitiePhone}`}>CALL NOW {CitiePhone}</a>
                    <a href="/book">SCHEDULE ONLINE</a>
                </div>
                <div className="MainBanner5Right">
                    <img src={Chimney2} alt="Air Duct Cleaning Near Melifornia" />
                    <div className="MainBanner5RightBubble1"></div>
                    <div className="MainBanner5RightBubble2"></div>
                </div>

            </div>
            <div className="MainBanner6">
                <div className="MainBanner6Left">
                    <h2>GOT QUESTIONS? WE'VE GOT ANSWERS.</h2>
                    <h3>Explore Our FAQs for Your Air Duct Needs or Watch Our Latest Videos.</h3>
                    <a href="/faq">READ OUR Air Duct FAQS</a>
                </div>
                <div className="MainBanner6Right">
                    <img src={bgnobgs} alt="Air Duct Cleaning In BHO | Air Duct Repair In BHO | Air Duct Services In BHO | Air Duct Inspection" />
                </div>
            </div>
            <div className="MainBanner7">
                <h1>Real Testimonials from Our Valued Clients</h1>
                <div className="MainBanner7Maein">
                    <div className="MainBanner7Box">
                        <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                        <h2>I had an excellent experience with BHO Air Duct Cleaning for {Content ? Content.Title : "SERVICES"}. The team was punctual, professional, and very thorough in their work. They not only cleaned my Air Duct but also provided valuable advice on maintaining it. I feel much safer knowing my Air Duct is in good hands!</h2>
                        <h4>John M.</h4>
                    </div>
                    <div className="MainBanner7Box">
                        <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                        <h2>BHO Air Duct Cleaning exceeded my expectations. Their technicians were friendly and knowledgeable, explaining each step of the inspection and cleaning process. They even found and fixed a small issue I wasn't aware of. I highly recommend their services to anyone in the area.</h2>
                        <h4>Sarah T.</h4>
                    </div>
                    <div className="MainBanner7Box">
                        <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                        <h2>I’ve used BHO Air Duct Cleaning for {Content ? Content.Title : "SERVICES"} cleaning and repairs, and I’m always impressed with their level of service. They use top-quality materials and are very detail-oriented. My Air Duct has never been in better shape, and I appreciate their commitment to safety and customer satisfaction.</h2>
                        <h4>David R.</h4>
                    </div>
                </div>
                <a href={`/reviews/local`}>MORE FROM OUR CUSTOMERS &gt;</a>
            </div>
            <div className="MainBanner4">
                <div className="MainBanner4Right">
                    <h1>OUR OUR Air Duct SERVICES</h1>
                    <h2>At BHO Air Duct Cleaning, we provide a full range of Air Duct services, including cleaning, inspection, repair, and maintenance. Our certified technicians are dedicated to ensuring your Air Duct is safe, efficient, and in optimal condition. We use only high-quality materials and cutting-edge equipment to deliver outstanding results, enhancing both the safety and comfort of your home while giving you peace of mind.</h2>
                    <div className="MainBanner4RightBenefits">
                        {AreaData.map((A, index) =>
                            <a href={A.Path} className="Header1BoxMenuBox">
                                {A.TitleShortVery}
                            </a>
                        )}
                    </div>
                </div>
            </div>

            <div className="BlogsListFaq">
                <div className="BlogsListFaqHead">
                    <h1>Frequently asked questions</h1>
                    <a href="/faq">View All</a>
                </div>
                <div className="BlogsListFaqList">
                    {FaqList.slice(getNumberFaq, (getNumberFaq + 5)).map((A, index) =>
                        <article key={index} className="FaqListBox">
                            <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                <h1>{A.question}</h1>
                                {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                <h3><span> {A.category}</span></h3>
                            </a>
                        </article>

                    )}
                </div>
            </div>
            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                    <a href="/blog">View All</a>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>

                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                            <article>
                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>
                        </a>
                    )}
                </div>
            </div>
            <NavigatorPath />
        </div>
    )
}

