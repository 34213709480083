const LocationReviewsData = [
    {
      "date": "2017-05-10",
      "review_rate": 4.8,
      "customer_name": "Karen L.",
      "service_type": "Air Duct Cleaning",
      "tech_name": "Mike T.",
      "review": "Our air ducts were in serious need of a clean, and we chose BHO Air Duct Cleaning. The team was punctual and professional, explaining each step of the process. They completed the job efficiently, and we noticed the improvement in air quality immediately. I was impressed by their attention to detail and commitment to customer satisfaction. I would definitely use their services again."
    },
    {
      "date": "2017-06-22",
      "review_rate": 4.9,
      "customer_name": "John P.",
      "service_type": "Air Duct Repairs",
      "tech_name": "David B.",
      "review": "BHO Air Duct Cleaning did a fantastic job repairing our ducts. The technicians were friendly, arrived on time, and thoroughly explained the repairs needed. After they completed the work, our system was running much more efficiently, and we even noticed a drop in our energy bills. I highly recommend their repair services for anyone dealing with duct issues."
    },
    {
      "date": "2017-09-15",
      "review_rate": 5.0,
      "customer_name": "Stephanie G.",
      "service_type": "Dryer Vent Cleaning",
      "tech_name": "George K.",
      "review": "We had BHO come out for a dryer vent cleaning, and the service was top-notch. The technicians were professional and efficient, and they took the time to explain how regular cleaning could prevent potential fire hazards. The dryer now works faster, and we feel safer knowing the vent is clear. I appreciate their thoroughness and would recommend them to others."
    },
    {
      "date": "2018-01-05",
      "review_rate": 4.7,
      "customer_name": "Chris D.",
      "service_type": "Air Duct Inspection",
      "tech_name": "John H.",
      "review": "We contacted BHO Air Duct Cleaning for an inspection, and they exceeded our expectations. The technicians conducted a detailed inspection and informed us about a few potential issues we hadn’t noticed before. They provided recommendations without any pressure to commit to additional services. We were impressed by their honesty and knowledge, and we'll be sure to use their services again."
    },
    {
      "date": "2018-04-10",
      "review_rate": 4.9,
      "customer_name": "Angela S.",
      "service_type": "Air Duct Cleaning",
      "tech_name": "Jake R.",
      "review": "BHO Air Duct Cleaning is simply the best! From the moment I booked the appointment, the staff was courteous and attentive. The cleaning was thorough, and the technicians explained every step, answering all my questions. The air in my home feels so much fresher now, and I’m really happy with the results. I’ll definitely be scheduling regular cleanings with them in the future."
    },
    {
      "date": "2018-08-22",
      "review_rate": 4.8,
      "customer_name": "Michael F.",
      "service_type": "Air Duct Repairs",
      "tech_name": "Sam W.",
      "review": "We had some issues with our air ducts, so we contacted BHO for repairs. The team did an excellent job fixing the leaks and sealing any gaps. They were professional, explained the problem clearly, and worked efficiently to resolve it. Since the repairs, our HVAC system has been performing better, and our home feels more comfortable. Highly recommended for anyone needing duct repair services."
    },
    {
      "date": "2018-10-05",
      "review_rate": 4.6,
      "customer_name": "Debbie T.",
      "service_type": "Dryer Vent Cleaning",
      "tech_name": "Mark C.",
      "review": "We needed our dryer vent cleaned, and BHO Air Duct Cleaning came highly recommended. The technicians were professional and friendly, and they completed the job quickly. I was impressed by how thorough they were, and they provided some great tips to maintain the vent between services. My dryer runs much more efficiently now, and I feel better knowing there’s no risk of lint buildup."
    },
    {
      "date": "2019-01-18",
      "review_rate": 4.8,
      "customer_name": "Emily V.",
      "service_type": "Air Duct Inspection",
      "tech_name": "Tom H.",
      "review": "The team at BHO did a fantastic job inspecting our air ducts. They were punctual and professional, providing a detailed report on the condition of our ducts. They also gave us a few maintenance tips to keep everything running smoothly. We were very pleased with their service and will definitely call them again for any future needs."
    },
    {
      "date": "2019-05-10",
      "review_rate": 5.0,
      "customer_name": "Jacob M.",
      "service_type": "Air Duct Cleaning",
      "tech_name": "Alex P.",
      "review": "We had our ducts cleaned by BHO, and the difference is amazing. The technicians were professional, thorough, and explained the process clearly. The air feels cleaner, and we’ve noticed less dust around the house. I appreciated their attention to detail and would not hesitate to recommend them to anyone looking for quality air duct cleaning services."
    },
    {
      "date": "2019-08-25",
      "review_rate": 4.7,
      "customer_name": "Laura K.",
      "service_type": "Air Duct Repairs",
      "tech_name": "Jason T.",
      "review": "Our ducts needed some repairs, and BHO Air Duct Cleaning provided excellent service. They arrived on time, were very polite, and worked diligently to fix the issues. After the repairs, our HVAC system was much more efficient, and we noticed a decrease in our utility bills. I would highly recommend their services to anyone dealing with duct problems."
    },
    {
      "date": "2020-02-12",
      "review_rate": 4.9,
      "customer_name": "Beth R.",
      "service_type": "Dryer Vent Cleaning",
      "tech_name": "Steve M.",
      "review": "I had no idea how much lint was trapped in our dryer vent until BHO Air Duct Cleaning showed us. The service was fast, effective, and reasonably priced. The technicians were very professional and explained the importance of regular vent cleanings. I now feel much safer knowing our dryer vent is clear. I’ll definitely be a returning customer."
    },
    {
      "date": "2020-04-08",
      "review_rate": 5.0,
      "customer_name": "Samuel N.",
      "service_type": "Air Duct Inspection",
      "tech_name": "Paul R.",
      "review": "We scheduled an air duct inspection with BHO, and they were great from start to finish. The technicians were friendly and conducted a thorough check of the entire system. They identified a few issues and recommended solutions without pushing for additional services. It’s rare to find such honest and reliable service. We’ll be calling them again for future inspections."
    },
    {
      "date": "2020-06-30",
      "review_rate": 4.8,
      "customer_name": "Olivia W.",
      "service_type": "Air Duct Cleaning",
      "tech_name": "Joe D.",
      "review": "I’m so glad we chose BHO Air Duct Cleaning. The technicians were punctual, professional, and very detail-oriented. They made sure to clean every duct thoroughly, and we could immediately feel the difference in air quality. The entire experience was smooth and stress-free, and I will definitely be using their services again in the future."
    }
  ]
  
export default LocationReviewsData;
