import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './BookAnAppointment.scss';
import emailjs from 'emailjs-com';
import { Helmet } from "react-helmet";
import HomeAdvisor from '../../Images/HomeAdvisor.png'
import BBB from '../../Images/BBB.png'
import Angie from '../../Images/Angie.png'
import YELP from '../../Images/YELP.png'
import Google from '../../Images/Google.png'
import Trust from '../../Images/Trust.png'
import Porch from '../../Images/Porch.png'
import Image1Logo from '../../ImagesLogos/Image1Logo.png'
const BookAnAppointment = ({ CitiePhone, CitiesData, setBookAnAppointment }) => {
    const [Page1, setPage1] = useState(true);
    const [Page2, setPage2] = useState(false);
    const [Page3, setPage3] = useState(false);
    const [FormSubmiting, setFormSubmiting] = useState(false);
    const [FormSubmited, setFormSubmited] = useState(false);
    const [CustInfoUpdate, setCustInfoUpdate] = useState(false);
    const url = new URL(window.location.href);

    const [CustInfo, setCustInfo] = useState({
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        Email: '',
        Service: 'Air Duct Cleaning $99',
        Date: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toISOString().substr(0, 10),
        Time: '8:00AM-11:00AM',
        Address1: '',
        Address2: '',
        City: '',
        State: CitiesData ? CitiesData.Full.split(',')[1].replace(' ', '') : 'Alabama',
        Zipcode: '',
        Note: ''
    });

    const FinalMessage = `
      Company name: Air Duct Cleaning
      Name: ${CustInfo.FirstName + ' ' + CustInfo.LastName}
      Phone: ${CustInfo.PhoneNumber}
      Address: ${CustInfo.Address1 + ', ' + CustInfo.City + ', ' + CustInfo.State + ', ' + CustInfo.Zipcode + ' ' + CustInfo.Address2}
      Type: ${CustInfo.Service}
      Note: ${CustInfo.Note}
      The Job date: ${CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0] + ' ' + CustInfo.Time.split('-')[0]}
      The Job end date: ${CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0] + ' ' + CustInfo.Time.split('-')[1]}
      Job type: Chimney Sweep
      Job source: Schedule Online
      `
    // 
    const location = useLocation()
    const navigate = useNavigate()
    function sendEmail(e) {
        setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_n0o8yjw', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                setPage1(false)
                setPage2(false)
                setPage3(true)
                setFormSubmiting(false)
                {
                    CitiesData ?
                        navigate(`/thanks${location.search}&name=${CustInfo.FirstName}&service=${CustInfo.Service}&message=Your appointment has been confirmed for ${CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0]} between ${CustInfo.Time} at ${CustInfo.Address1 + ', ' + CustInfo.City + ', ' + CustInfo.State + ', ' + CustInfo.Zipcode + ' ' + CustInfo.Address2}`)
                        :
                        navigate(`/thanks?name=${CustInfo.FirstName}&message=Your appointment has been confirmed for ${CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0]} between ${CustInfo.Time} at ${CustInfo.Address1 + ', ' + CustInfo.City + ', ' + CustInfo.State + ', ' + CustInfo.Zipcode + ' ' + CustInfo.Address2}`)
                }
                sentMessage()
            }, (error) => {
                console.log(error.text);
            });
    }
    const sentMessage = async () => {
        const res = await fetch('https://api.callrail.com/v3/a/317-421-447/text-messages.json', {
            method: 'POST',
            headers: {
                'Authorization': 'Token token=dd02c8e45b65e07d612f8a21a51d9e1d',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'customer_phone_number': `${CustInfo.PhoneNumber.replace(/-/g, '')}`,
                'tracking_number': `${CitiesData ? CitiePhone.replace(/-/g, '') : '8666459692'}`,
                'content': `
Hi ${CustInfo.FirstName} 😊,
Thank you for booking your Air Duct service with BHO Air Duct Cleaning! Your appointment has been confirmed for ${CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0]} Between ${CustInfo.Time} At ${CustInfo.Address1 + ', ' + CustInfo.City + ', ' + CustInfo.State + ', ' + CustInfo.Zipcode + ' ' + CustInfo.Address2}

Our Technician will reach you out 30Min in advance at the appointment day.

Best regards 🤝
BHO Air Duct Cleaning.
                `,
                'company_id': '447405602'
            })
        })
        const data = await res.json()
    }
    return (
        <div className="BookAnAppointmentMainComponent" style={location.pathname == '/book' ? { "min-height": "100vh" } : null}>
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Schedule Air Duct Service Online | BHO Air Duct Cleaning | 866-645-9692</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Schedule Air Duct Service Online | BHO Air Duct Cleaning | 866-645-9692" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Schedule Air Duct Service Online | BHO Air Duct Cleaning | 866-645-9692" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* Blog Tags */}
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content="article" />
                <meta name="SubCategories" content="article" />
                <meta name="FunnelStage" content="upper" />
                <meta name="Objective" content="education, seo" />


                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.bhoairductcleaning.com/book" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Easily schedule your professional Air Duct service online with BHO Air Duct Cleaning. Book inspections, cleanings, and repairs at your convenience to keep your Air Duct safe and efficient." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Easily schedule your professional Air Duct service online with BHO Air Duct Cleaning. Book inspections, cleanings, and repairs at your convenience to keep your Air Duct safe and efficient." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Schedule Air Duct Service Online | BHO Air Duct Cleaning | 866-645-9692" data-react-helmet="true" />
                <meta name="keywords" content="Air Duct Cleaning, Air Duct Inspection, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct services, Air Duct service, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Insulation, Air Duct Installation, Air Duct Liner Repair, Air Duct Pointing, Air Duct Rainp Installation, Air Duct Repair, Air Duct Crown Repair, Air Duct Restoration, Air Duct Flue Installation, Air Duct Flue Repair, ​Air Duct Construction, Air Duct Vent Installation, Air Duct Flashing, Flexible Air Duct Liner Installation, Air Duct Damper Repair, Chimneyp Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rainp Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplacep, Faq Page" data-react-helmet="true" />
            </Helmet>
            <div className="BookAnAppointmentMainComponentTop">
                <div className="BookAnAppointmentMainComponentTopLeft">
                    <h1>SCHEDULE SERVICE ONLINE</h1>
                    <h2>Servicing <b>{CitiesData ? CitiesData.Full.toUpperCase() : "Your Area"}</b> And Surrounding Areas - BHO Air Duct Cleaning</h2>
                    <h2>No extra charge for appointments on nights, weekends or holidays</h2>
                </div>
            </div>
            <div className='BookAnAppointmentMainComponentTopMain'>
                <div className="BookAnAppointmentMainComponentMaps">
                    <h4 style={Page1 || Page2 || Page3 ? {
                        "borderTop": "2px solid #DA291C"
                    } : null}>1. Information </h4>
                    <h4 style={Page2 || Page2 || Page3 ? {
                        "borderTop": "2px solid #DA291C"
                    } : null}>2. Address </h4>
                    <h4 style={Page3 ? {
                        "borderTop": "2px solid #DA291C"
                    } : null}>3. Note </h4>
                </div>
                {
                    Page1 ?
                        <div className="BookAnAppointmentMainComponentPage1">
                            <div className="BookAnAppointmentMainRightFullName">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Full Name <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.FirstName}
                                        className='BookAnAppointmentMainRightBoxFirstName'
                                        onChange={(e) => {
                                            CustInfo.FirstName = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} type="text" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Phone Number <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.PhoneNumber}
                                        className='BookAnAppointmentMainRightBoxPhone'
                                        onChange={(e) => {
                                            CustInfo.PhoneNumber = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} type="tel" />
                                </div>
                                {/* <div className="BookAnAppointmentMainRightBox">
                                    <h4>Last Name <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.LastName}
                                        className='BookAnAppointmentMainRightBoxLastName'
                                        onChange={(e) => {
                                            CustInfo.LastName = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            
                                        }} type="text" />
                                </div> */}
                            </div>
                            {/* <div className="BookAnAppointmentMainRightPhoneAndMaill">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Phone Number <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.PhoneNumber}
                                        className='BookAnAppointmentMainRightBoxPhone'
                                        onChange={(e) => {
                                            CustInfo.PhoneNumber = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            
                                        }} type="tel" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Email Address (Optional)</h4>
                                    <input
                                        defaultValue={CustInfo.Email}
                                        onChange={(e) => {
                                            CustInfo.Email = e.target.value
                                            setCustInfoUpdate(e => !e)
                                            
                                        }} type="text" />
                                </div>
                            </div> */}
                            <div className="BookAnAppointmentMainRightDateAndTime">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Appointment Date <span>*</span></h4>
                                    <input
                                        defaultValue={CustInfo.Date}
                                        className='BookAnAppointmentMainRightBoxDate'
                                        onChange={(e) => {
                                            CustInfo.Date = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} type="date" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Appointment Time <span>*</span></h4>
                                    <select
                                        defaultValue={CustInfo.Time}
                                        className='BookAnAppointmentMainRightBoxTime'
                                        onChange={(e) => {
                                            CustInfo.Time = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} >
                                        <option value="8:00AM-11:00AM">8:00AM-11:00AM</option>
                                        <option value="11:00AM-01:00PM">11:00AM-01:00PM</option>
                                        <option value="01:00PM-03:00PM">01:00PM-04:00PM</option>
                                        <option value="04:00PM-07:00PM">04:00PM-07:00PM</option>
                                        <option value="Ask for a Representative">Ask for a Representative</option>
                                    </select>
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainComponentButtons">
                                <button onClick={() => {

                                    if (!CustInfo.FirstName) { document.querySelector(".BookAnAppointmentMainRightBoxFirstName").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxFirstName").style.border = "2px solid #6f6f6f61"; }
                                    // if (!CustInfo.LastName) { document.querySelector(".BookAnAppointmentMainRightBoxLastName").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxLastName").style.border = "2px solid #6f6f6f61"; }
                                    if (!CustInfo.PhoneNumber) { document.querySelector(".BookAnAppointmentMainRightBoxPhone").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxPhone").style.border = "2px solid #6f6f6f61"; }
                                    if (!CustInfo.Date) { document.querySelector(".BookAnAppointmentMainRightBoxDate").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxDate").style.border = "2px solid #6f6f6f61"; }
                                    if (!CustInfo.Time) { document.querySelector(".BookAnAppointmentMainRightBoxTime").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxTime").style.border = "2px solid #6f6f6f61"; }

                                    if (
                                        CustInfo.FirstName &&
                                        // CustInfo.LastName &&
                                        CustInfo.PhoneNumber &&
                                        CustInfo.Service &&
                                        CustInfo.Date &&
                                        CustInfo.Time
                                    ) {
                                        setPage1(false)
                                        setPage2(true)
                                    } else {
                                        console.log('no')
                                    }
                                }}>NEXT</button>
                            </div>
                        </div>
                        : null
                }
                {
                    Page2 ?
                        <div className="BookAnAppointmentMainComponentPage2">
                            <div className="BookAnAppointmentMainRightAddress1">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Address Line 1 <span>*</span></h4>
                                    <input
                                        className='BookAnAppointmentMainRightBoxAddress1'
                                        defaultValue={CustInfo.Address1}
                                        onChange={(e) => {
                                            CustInfo.Address1 = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightAddress2">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Address Line 2 (Optional)</h4>
                                    <input
                                        defaultValue={CustInfo.Address2}
                                        onChange={(e) => {
                                            CustInfo.Address2 = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightAddress3">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>City <span>*</span></h4>
                                    <input
                                        className='BookAnAppointmentMainRightBoxCity'
                                        defaultValue={CustInfo.City}
                                        onChange={(e) => {
                                            CustInfo.City = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} type="text" />
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>State <span>*</span></h4>
                                    <select
                                        defaultValue={CitiesData ? CitiesData.Full.split(',')[1].replace(' ', '') : 'Alabama'}
                                        onChange={(e) => {
                                            CustInfo.State = e.target.value
                                            setCustInfoUpdate(e => !e)
                                        }}>
                                        {/* <option value="" disabled selected>Select your State</option> */}
                                        <option value="Alabama">Alabama</option>
                                        <option value="Alaska">Alaska</option>
                                        <option value="Arizona">Arizona</option>
                                        <option value="Arkansas">Arkansas</option>
                                        <option value="California">California</option>
                                        <option value="Colorado">Colorado</option>
                                        <option value="Connecticut">Connecticut</option>
                                        <option value="Delaware">Delaware</option>
                                        <option value="District Of Columbia">District Of Columbia</option>
                                        <option value="Florida">Florida</option>
                                        <option value="Gecomia">Gecomia</option>
                                        <option value="Hawaii">Hawaii</option>
                                        <option value="Idaho">Idaho</option>
                                        <option value="Illinois">Illinois</option>
                                        <option value="Indiana">Indiana</option>
                                        <option value="Iowa">Iowa</option>
                                        <option value="Kansas">Kansas</option>
                                        <option value="Kentucky">Kentucky</option>
                                        <option value="Louisiana">Louisiana</option>
                                        <option value="Maine">Maine</option>
                                        <option value="Maryland">Maryland</option>
                                        <option value="Massachusetts">Massachusetts</option>
                                        <option value="Michigan">Michigan</option>
                                        <option value="Minnesota">Minnesota</option>
                                        <option value="Mississippi">Mississippi</option>
                                        <option value="Missouri">Missouri</option>
                                        <option value="Montana">Montana</option>
                                        <option value="Nebraska">Nebraska</option>
                                        <option value="Nevada">Nevada</option>
                                        <option value="New Hampshire">New Hampshire</option>
                                        <option value="New Jersey">New Jersey</option>
                                        <option value="New Mexico">New Mexico</option>
                                        <option value="New York">New York</option>
                                        <option value="Northrolina">Northrolina</option>
                                        <option value="North Dakota">North Dakota</option>
                                        <option value="Ohio">Ohio</option>
                                        <option value="Oklahoma">Oklahoma</option>
                                        <option value="Oregon">Oregon</option>
                                        <option value="Pennsylvania">Pennsylvania</option>
                                        <option value="Rhode Island">Rhode Island</option>
                                        <option value="Southrolina">Southrolina</option>
                                        <option value="South Dakota">South Dakota</option>
                                        <option value="Tennessee">Tennessee</option>
                                        <option value="Texas">Texas</option>
                                        <option value="Utah">Utah</option>
                                        <option value="Vermont">Vermont</option>
                                        <option value="Virginia">Virginia</option>
                                        <option value="Washington">Washington</option>
                                        <option value="West Virginia">West Virginia</option>
                                        <option value="Wisconsin">Wisconsin</option>
                                        <option value="Wyoming">Wyoming</option>
                                    </select>
                                </div>
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Zipcode <span>*</span></h4>
                                    <input
                                        className='BookAnAppointmentMainRightBoxZipcode'
                                        defaultValue={CustInfo.Zipcode}
                                        onChange={(e) => {
                                            CustInfo.Zipcode = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} type="tel" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainComponentButtons">
                                <button onClick={() => {

                                    setPage1(true)
                                    setPage2(false)
                                    setPage3(false)
                                }}>PREVIOUS</button>
                                <button onClick={() => {

                                    if (!CustInfo.Address1) { document.querySelector(".BookAnAppointmentMainRightBoxAddress1").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxAddress1").style.border = "2px solid #6f6f6f61"; }
                                    if (!CustInfo.City) { document.querySelector(".BookAnAppointmentMainRightBoxCity").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxCity").style.border = "2px solid #6f6f6f61"; }
                                    if (!CustInfo.Zipcode) { document.querySelector(".BookAnAppointmentMainRightBoxZipcode").style.border = "2px solid red"; } else { document.querySelector(".BookAnAppointmentMainRightBoxZipcode").style.border = "2px solid #6f6f6f61"; }

                                    if (
                                        CustInfo.Address1 &&
                                        CustInfo.City &&
                                        CustInfo.Zipcode
                                    ) {
                                        setPage1(false)
                                        setPage2(false)
                                        setPage3(true)
                                    } else {
                                        console.log('no')
                                    }

                                }}>NEXT</button>
                            </div>
                        </div>
                        : null
                }
                {
                    Page3 ?
                        <div className="BookAnAppointmentMainComponentPage3">

                            <div className="BookAnAppointmentMainRightNote">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Note (Optional)</h4>
                                    <textarea
                                        defaultValue={CustInfo.Note}
                                        onChange={(e) => {
                                            CustInfo.Note = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} type="text" />
                                </div>
                            </div>
                            <div className="BookAnAppointmentMainRightServices">
                                <div className="BookAnAppointmentMainRightBox">
                                    <h4>Service Needed <span>*</span></h4>
                                    <select
                                        defaultValue={CustInfo.Service}
                                        onChange={(e) => {
                                            CustInfo.Service = e.target.value
                                            setCustInfoUpdate(e => !e)

                                        }} name="" id="">
                                        <option value="Air Duct Inspection $69">Air Duct Cleaning $99</option>
                                        <option value="Dryer Vent Cleaning $79">Dryer Vent Cleaning $79</option>
                                        <option value="Air Duct Free Inspection">Air Duct Free Inspection</option>
                                        <option value="Dryer Vent Free Inspection">Dryer Vent Free Inspection</option>
                                    </select>
                                    <h3>Technician will provide inspection report on-site. Payment  required only if/when service is done.</h3>

                                </div>
                            </div>
                            <div className="BookAnAppointmentMainComponentButtons">
                                <button onClick={() => {
                                    setPage1(false)
                                    setPage2(true)
                                    setPage3(false)
                                }}>PREVIOUS</button>

                                {
                                    !FormSubmiting ?
                                        <form className="contact-form" onSubmit={sendEmail}>
                                            <input type="hidden" name="from_name" defaultValue={FinalMessage} />
                                            {/* Cust Info */}
                                            <input type="hidden" name="Date" defaultValue={CustInfo.Date.split("-")[1] + '/' + CustInfo.Date.split("-")[2] + '/' + CustInfo.Date.split("-")[0] + ' ' + CustInfo.Time} />
                                            <input type="hidden" name="Service_Type" defaultValue={CustInfo.Service} />
                                            <input type="hidden" name="Send_To" defaultValue={CustInfo.Email} />
                                            <input type="hidden" name="Cust_Name" defaultValue={CustInfo.FirstName} />
                                            <input type="hidden" name="Company_From" defaultValue={`BHO Air Duct Cleaning`} />
                                            <input type="hidden" name="Company" defaultValue={`BHO Air Duct Cleaning | ${CustInfo.City + ', ' + CustInfo.State}`} />
                                            <input type="submit" value="SEND" />
                                        </form>
                                        :
                                        null
                                }

                                {
                                    FormSubmiting ?
                                        <button class="buttonload">
                                            <i class="fa fa-refresh fa-spin"></i>Loading
                                        </button>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        : null
                }
                {location.pathname == "/local" ? null : <div className="BookAnAppointmentMainComponentEmergery">

                    <div className="BookAnAppointmentMainComponentRates">
                        <div className="BookAnAppointmentMainComponentRatesImages">
                            <img src={YELP} alt="BHO Air Duct Cleaning Yelp Review" />
                            <img src={Angie} alt="BHO Air Duct Cleaning Angie Review" />
                            <img src={HomeAdvisor} alt="BHO Air Duct Cleaning HomeAdvisor Review" />
                            <img src={BBB} alt="BHO Air Duct Cleaning BBB Review" />
                            <img src={Google} alt="BHO Air Duct Cleaning Google Review" />
                            <img src={Porch} alt="BHO Air Duct Cleaning Porch Review" />
                            <img src={Trust} alt="BHO Air Duct Cleaning Trust Review" />
                        </div>
                        <h1>Customer Testimonials</h1>
                        <h2>⭐⭐⭐⭐⭐</h2>
                        <h3>I’ve relied on BHO Air Duct Cleaning for both Air Duct & Dryer Vent Cleaning and repair services, and they consistently exceed my expectations. The team uses high-quality materials and pays great attention to detail. My air ducts are in the best condition they’ve ever been, thanks to their meticulous work. I truly value their dedication to safety and customer satisfaction, which keeps me coming back for all my duct needs.</h3>
                    </div>
                    <div className="BookAnAppointmentMainComponentEmergeryBox">
                        <a href={`tel:${CitiePhone}`}>For emergency service Call: {CitiePhone}</a>
                    </div>
                </div>
                }
            </div>
            {/* // HomePageGoogleFooter */}
            {url.searchParams.get('cityid')  && location.pathname == "/book" ?
                <div className="HomePageGoogleFooter">
                    <div className="HomePageGoogleFooterTop">
                        <div className="HomePageGoogleFooterTopTitle">
                            <img src={require(`../../Images/Music.png`)} />
                            <h1>Air So Clean, Ducts Unseen<br />BHO’s On The Scene!</h1>
                            <img src={require(`../../Images/Music.png`)} />
                        </div>
                        <div className="HomePageGoogleFooterTopImg">
                            <img src={require(`../../ImagesLogos/Image1Logo.png`)} />
                        </div>
                    </div>
                    <div className="HomePageGoogleFooterBottom">
                        <div className="HomePageGoogleFooterBottomTop">
                            <img src={require(`../../ImagesLogos/Banner2Logo.png`)} />
                            <p>Copyright 1975-2024 BHO Air Duct Cleaning. All rights reserved. All available services, hours of operations, pricing structure, and guarantees may vary by location.</p>
                        </div>
                        <div className="HomePageGoogleFooterBottomBottom">
                            <span>
                                <img src={require(`../../Images/tag.png`)} alt="" />
                                <a>www.bhoairductcleaning.com</a>
                            </span>
                            <span>
                                <img src={require(`../../Images/tag.png`)} alt="" />
                                <a href={`tel:${CitiePhone}`}>{CitiePhone}</a>
                            </span>
                            <span>
                                <img src={require(`../../Images/tag.png`)} alt="" />
                                <a>info@bhoairductcleaning.com</a>
                            </span>
                        </div>
                    </div>
                </div>
                :
                null}
        </div >
    );
}

export default BookAnAppointment;
