import React, { useState } from 'react'
import './ThanksContact.scss'
import appcalendar from '../../Images/appcalendar.png'
import bgnobgs from '../../Images/bgnobgs.png'

import { Helmet } from "react-helmet";
// var __html = require('./ThanksContactJS.html');

export default function ThanksContact({ CitiePhone }) {

    const url = new URL(window.location.href);
    console.log(url.searchParams.get('url'));
    console.log(url.searchParams.get('name'));
    console.log(url.searchParams.get('message'));
    return (
        <div className="ThanksContact">
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <img src={appcalendar} alt="" />
                    <h1>Thank you!</h1>
                    <h2>Request Received!</h2>
                    <h4>
                    Thank you for contacting us. Our team is currently processing your request and will contact you shortly. If you have urgent inquiries, please call us at <a href={`tel:${CitiePhone}`}>{CitiePhone}</a>. We look forward to ensuring your Air Duct is safe and efficient.
                        <br></br>
                        <br></br>
                        Thank you for contact BHO Air Duct Cleaning! We appreciate your trust in our services.
                    </h4>
                    <a className="ahomepagethamnk"href={url.searchParams.get('url')}>Click to go back to Home Page</a>
                </div>
                <div className="MainBannerRight">
                    <img src={bgnobgs} alt="Air Duct Cleaning BHO | Air Duct Repair BHO | Air Duct Services BHO | Air Duct Inspection BHO" />
                    <div className="MainBannerRightBubble1"></div>
                    <div className="MainBannerRightBubble2"></div>
                </div>
            </div>

        </div>
    )
}


