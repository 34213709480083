import React, { useState } from 'react'
import './Thanks.scss'
import appcalendar from '../../Images/appcalendar.png'
import bgnobgs from '../../Images/bgnobgs.png'

import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';
// var __html = require('./ThanksJS.html');

export default function Thanks({ CitiePhone }) {
    const location = useLocation();

    const url = new URL(window.location.href);
    console.log(url.searchParams.get('cityid'));
    console.log(location.pathname);
    return (
        <div className="Thanks">
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <img src={appcalendar} alt="" />
                    <h1>{url.searchParams.get('name') ? url.searchParams.get('name') : "Hi"}, We've got you confirmed for your appointment</h1>
                    <h2>{url.searchParams.get('message') ? url.searchParams.get('message') : "null"}</h2>
                    <h3>{url.searchParams.get('service') ? url.searchParams.get('service') : "null"}</h3>
                    <h4>
                        The technician will reach you out 30min in advance at the appointment day. If you have any questions or need to reschedule, please contact us at <a href={`tel:${CitiePhone}`}>{CitiePhone}</a>. We look forward to ensuring your Air Duct is safe and efficient.
                        <br></br>
                        <br></br>
                        Thank you for booking your appointment with BHO Air Duct Cleaning! We appreciate your trust in our services.
                    </h4>
                    <a className="ahomepagethamnk"href={url.searchParams.get('cityid') ? '/local' + '?cityid=' +  url.searchParams.get('cityid') + '&cityphone=' +  url.searchParams.get('cityphone'): '/local'}>Click to go back to Home Page</a>
                </div>
                <div className="MainBannerRight">
                    <img src={bgnobgs} alt="Air Duct Cleaning BHO | Air Duct Repair BHO | Air Duct Services BHO | Air Duct Inspection BHO" />
                    <div className="MainBannerRightBubble1"></div>
                    <div className="MainBannerRightBubble2"></div>
                </div>
            </div>

        </div>
    )
}


