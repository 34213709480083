import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import './SiteMap.scss';

// import { useLocation } from "react-router-dom";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useEffect } from 'react';
import AreaData from '../../Components/ServicesPage/AreaData'
import CitiesData from '../../Components/HomePageCity/CitiesData'


export default function SiteMap({ Blogs, FaqList, WebSitePages }) {
    const location = useLocation()
    const [SiteMapToArray, setSiteMapToArray] = useState([]);


    useEffect(() => {
        if (location.pathname.split('/')[2] == "website" || location.pathname.split('/')[1] == "sitemaps" || location.pathname.split('/').length <= 2 && location.pathname.split('/')[1] == "sitemap") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "solutions") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "blog") {
            setSiteMapToArray(Blogs)
        } else if (location.pathname.split('/')[2] == "faq") {
            setSiteMapToArray(FaqList)
        } else if (location.pathname.split('/')[2] == "socialmedia") {
            setSiteMapToArray(WebSitePages)
        }
    }, [location.pathname]);

    return (
        <div className="SiteMap">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>BHO Air Duct Cleaning | Site Map</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="BHO Air Duct Cleaning | Site Map" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="BHO Air Duct Cleaning | Site Map" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.bhoairductcleaning.com/sitemap" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the comprehensive sitemap for Air Duct Cleaning. Find detailed links to our Air Duct Cleaninging, cleaning, inspection, and repair services to ensure your home's safety and efficiency." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the comprehensive sitemap for Air Duct Cleaning. Find detailed links to our Air Duct Cleaninging, cleaning, inspection, and repair services to ensure your home's safety and efficiency." data-react-helmet="true" />
                <meta name="keywords" content="Air Duct Cleaninging, Air Duct cleaning, Air Duct inspection, Air Duct repair, Fireplace maintenance, Creosote removal, Air Duct safety, Air Duct flue cleaning, Soot removal, Air Duct maintenance services" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="BHO Air Duct Cleaning | Site Map" data-react-helmet="true" />
            </Helmet>
            <div className="SiteMapMenu">
                <a href="/">Website</a>
            </div>
            <div className="SiteMapDes">
                <div className='SiteMapDesBox'>
                    <a href="/">Home Page</a>
                    {/* Service */}
                    {CitiesData.map((A, index) =>
                        <a href={`/city/${A.url}`} className="Header1BoxMenuBox">
                            {A.City}, {A.State}
                        </a>
                    )}
                    <a href="/contact">Contact Us</a>
                    <a href="/book">Book Air Duct Cleaning Appointment Online In BHO</a>
                    <a href="/services">Our Services</a>
                    <a href="/blog">Blog</a>
                    <a href="/faq">Faq</a>
                    <a href="/sitemap">Site Map</a>
                    <a href='/reviews/local'>Customer Reviews</a>

                    {/* Service */}
                    {AreaData.map((A, index) =>
                        <a href={A.Path} className="Header1BoxMenuBox">
                            {A.Title}
                        </a>
                    )}


                    {/* Blog */}
                    <a href="/blog/chimney_sweep_services_in_BHO:_ensuring_safety_and_efficiency_with_BHO_chimney_sweep_3">Comprehensive Guide to Air Duct Inspection in BHO: How BHO Air Duct Cleaningn Assist You</a>
                    <a href="/blog/the_essential_guide_to_chimney_cleaning_in_BHO:_how_BHO_chimney_sweep_can_help_keep_your_home_safe_2">Comprehensive Guide to Air Duct Repair in BHO: How BHO Air Duct Cleaningn Assist You</a>
                    <a href="/blog/comprehensive_guide_to_chimney_repair_in_BHO:_how_BHO_chimney_sweep_can_assist_you_1">The Essential Guide to Air Duct Cleaning in BHO: How BHO Air Duct Cleaningn Help Keep You</a>
                    <a href="/blog/comprehensive_guide_to_chimney_inspection_in_BHO:_how_BHO_chimney_sweep_can_assist_you_0">Air Duct Cleaning Services in BHO: Ensuring Safety and Efficiency with BHO Air Duct Cleaning</a>
                    {/* Faq */}

                    <a href="/faq/How_often_should_I_have_my_chimney_cleaned_BHO_0">How often should I have my Air Duct cleaned BHO</a>
                    <a href="/faq/What_are_the_signs_that_my_chimney_needs_cleaning_BHO_1">What are the signs that my Air Duct needs cleaning BHO</a>
                    <a href="/faq/Can_I_clean_my_chimney_myself_BHO_2">Can I clean my Air Duct myself BHO</a>
                    <a href="/faq/What_is_creosote,_and_why_is_it_dangerous_BHO_3">What is creosote, and why is it dangerous BHO</a>
                    <a href="/faq/Does_chimney_cleaning_prevent_chimney_fires_BHO_4">Does Air Duct cleaning prevent Air Duct fires BHO</a>
                    <a href="/faq/What_is_the_purpose_of_a_chimney_inspection_BHO_5">What is the purpose of a Air Duct inspection BHO</a>
                    <a href="/faq/How_often_should_I_have_a_chimney_inspection_BHO_6">How often should I have a Air Duct inspection BHO</a>
                    <a href="/faq/What’s_the_difference_between_a_Level_1,_2,_and_3_chimney_inspection_BHO_7">What’s the difference between a Level 1, 2, and 3 Air Duct inspection BHO</a>
                    <a href="/faq/What_should_I_do_if_my_chimney_fails_inspection_BHO_8">What should I do if my Air Duct fails inspection BHO</a>
                    <a href="/faq/Can_a_chimney_inspection_detect_carbon_monoxide_issues_BHO_9">Can a Air Duct inspection detectrbon monoxide issues BHO</a>
                    <a href="/faq/What_are_common_chimney_repairs_BHO_10">What are common Air Duct repairs BHO</a>
                    <a href="/faq/How_do_I_know_if_my_chimney_needs_repair_BHO_11">How do I know if my Air Duct needs repair BHO</a>
                    <a href="/faq/How_much_do_chimney_repairs_typically_cost_BHO_12">How much do Air Duct repairs typically cost BHO</a>
                    <a href="/faq/How_long_does_chimney_repair_take_BHO_13">How long does Air Duct repair take BHO</a>
                    <a href="/faq/Can_chimney_repairs_be_done_year-round_BHO_14">Can Air Duct repairs be done year-round BHO</a>
                    <a href="/faq/What_does_a_chimney_sweep_do_BHO_15">What does a Air Duct Cleaning do BHO</a>
                    <a href="/faq/How_often_should_I_schedule_a_chimney_sweep_BHO_16">How often should I schedule a Air Duct Cleaning BHO</a>
                    <a href="/faq/What’s_the_difference_between_a_chimney_sweep_and_a_chimney_cleaning_BHO_17">What’s the difference between a Air Duct Cleaning and a Air Duct cleaning BHO</a>
                    <a href="/faq/How_can_I_prepare_for_a_chimney_sweep_visit_BHO_18">Hown I prepare for a Air Duct Cleaning visit BHO</a>
                    <a href="/faq/Is_a_chimney_sweep_messy_BHO_19">Is a Air Duct Cleaning messy BHO</a>
                    <a href="/faq/What_is_a_chimney_liner_BHO_20">What is a Air Duct liner BHO</a>
                    <a href="/faq/Why_might_I_need_a_new_chimney_liner_BHO_21">Why might I need a new Air Duct liner BHO</a>
                    <a href="/faq/How_long_does_chimney_liner_installation_take_BHO_22">How long does Air Duct liner installation take BHO</a>
                    <a href="/faq/What_types_of_chimney_liners_are_available_BHO_23">What types of Air Duct liners are available BHO</a>
                    <a href="/faq/How_much_does_chimney_liner_installation_cost_BHO_24">How much does Air Duct liner installation cost BHO</a>
                    <a href="/faq/What_is_a_chimney_crown_BHO_25">What is a Air Duct crown BHO</a>
                    <a href="/faq/How_do_I_know_if_my_chimney_crown_needs_repair_BHO_26">How do I know if my Air Duct crown needs repair BHO</a>
                    <a href="/faq/What_happens_if_I_don’t_repair_a_damaged_chimney_crown_BHO_27">What happens if I don’t repair a damaged Air Duct crown BHO</a>
                    <a href="/faq/How_long_does_chimney_crown_repair_take_BHO_28">How long does Air Duct crown repair take BHO</a>
                    <a href="/faq/Can_a_chimney_crown_be_replaced_BHO_29">Can a Air Duct crown be replaced BHO</a>
                    <a href="/faq/What_causes_chimney_draft_issues_BHO_30">Whatuses Air Duct draft issues BHO</a>
                    <a href="/faq/How_can_I_tell_if_I_have_a_chimney_draft_problem_BHO_31">Hown I tell if I have a Air Duct draft problem BHO</a>
                    <a href="/faq/Can_a_chimney_cap_help_with_draft_issues_BHO_32">Can a chimneyp help with draft issues BHO</a>
                    <a href="/faq/How_can_chimney_draft_issues_be_fixed_BHO_33">Hown Air Duct draft issues be fixed BHO</a>
                    <a href="/faq/Are_draft_issues_more_common_in_older_homes_BHO_34">Are draft issues more common in older homes BHO</a>

                </div>



            </div>
            <NavigatorPath />
        </div>
    )
}
