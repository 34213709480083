import React, { useEffect, useRef, useState } from 'react'
import './LocationReviews.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useLocation, useNavigate } from 'react-router-dom';
import Google from '../../Images/Google.png'
import book from '../../Images/book.png'
import telephone from '../../Images/telephone.png'
import Review from '../../Images/Review.png'
import locationpin from '../../Images/locationpin.png'
import LocationReviewsData from './LocationReviewsData'
// import GoogleMapReact from "google-map-react";

//
import HomeAdvisor from '../../Images/HomeAdvisor.png'
import BBB from '../../Images/BBB.png'
import Angie from '../../Images/Angie.png'
import YELP from '../../Images/YELP.png'
import Trust from '../../Images/Trust.png'
import Porch from '../../Images/Porch.png'

export default function LocationReviews({CitiePhone}) {
    const location = useLocation()


    return (
        <div className="LocationReviews">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>BHO Air Duct Cleaning Reviews 5 Stars Based on 1800 Reviews | Experts BHO Air Duct Cleaning</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content={`$BHO Air Duct Cleaning Reviews | 5 Stars Based on 1800 Reviews"} | Experts BHO Air Duct Cleaning`} data-react-helmet="true" />
                <meta name="og:title" property="og:title" content={`$BHO Air Duct Cleaning Reviews | 5 Stars Based on 1800 Reviews"} | Experts BHO Air Duct Cleaning`} data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content={`https://www.bhoairductcleaning.com.com/reviews/local/${location.pathname.split('/')[2]}`} data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content={`Discover Our Reviews At $BHO Air Duct Cleaning Location | 5 Stars Based on 1800 Reviews"} Air Duct Cleaning Repair in $BHO, area and surrounding cities.`} data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content={`Discover Our Reviews At $BHO Air Duct Cleaning Location | 5 Stars Based on 1800 Reviews"} Air Duct Cleaning Repair in $BHO, area and surrounding cities.`} data-react-helmet="true" />
                <meta name="keywords" content="Air Duct Cleaninging, Air Duct Cleaning, Air Duct Cleaning inspection, Air Duct Cleaning repair, Air Duct Cleaning maintenance, Creosote removal, Air Duct Cleaning safety, Air Duct Cleaning flue Repair, Soot removal, Air Duct Cleaning Maintenance Locations" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content={`BHO Air Duct Cleaning`} data-react-helmet="true" />
            </Helmet>
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>Hear What Satisfied Customers Have To Say About BHO Air Duct Cleaning</h1>
                    <h2>Welcome to BHO Top Air Duct Cleaning - Read Our Reviews!</h2>
                    <span>
                        <a href={`tel:${CitiePhone}`}>CALL {CitiePhone}<img src={telephone} alt="" /></a>
                        <a href="/book">BOOK AN APPOINTMENT<img src={book} alt="" /></a>
                    </span>
                    <div className="GoogleButt">
                        <h6>5 Stars - Based on 1,800 Reviews For BHO Air Duct Cleaning Repair</h6>
                    </div>
                    <div className="BookAnAppointmentMainComponentEmergery">
                        <div className="BookAnAppointmentMainComponentRates">
                            <div className="BookAnAppointmentMainComponentRatesImages">
                                <img src={YELP} alt="BHO Air Duct Cleaning Yelp Review" />
                                <img src={Angie} alt="BHO Air Duct Cleaning Angie Review" />
                                <img src={HomeAdvisor} alt="BHO Air Duct Cleaning HomeAdvisor Review" />
                                <img src={BBB} alt="BHO Air Duct Cleaning BBB Review" />
                                <img src={Google} alt="BHO Air Duct Cleaning Google Review" />
                                <img src={Porch} alt="BHO Air Duct Cleaning Porch Review" />
                                <img src={Trust} alt="BHO Air Duct Cleaning Trust Review" />
                            </div>
                            <h1>Customer Testimonials</h1>
                            <h2>⭐⭐⭐⭐⭐</h2>
                            <h3>
                                I’ve relied on BHO Air Duct Cleaning for both Air Duct & Dryer Vent Cleaning and repair services, and they consistently exceed my expectations. The team uses high-quality materials and pays great attention to detail. My air ducts are in the best condition they’ve ever been, thanks to their meticulous work. I truly value their dedication to safety and customer satisfaction, which keeps me coming back for all my duct needs.
                                <a href={`/reviews/local`}>MORE FROM OUR CUSTOMERS &gt;</a>
                            </h3>

                        </div>
                        <div className="BookAnAppointmentMainComponentEmergeryBox">
                            <a href={`tel:${CitiePhone}`}>For emergency service Call: {CitiePhone}</a>
                        </div>
                    </div>
                </div>

            </div>

            <div className="LocationReviewsListMap">
                <div className="LocationReviewsLsit">
                    {LocationReviewsData.map((A, index) =>
                        <div key={index} className="LocationReviewsBox">
                            <div className="LocationReviewsBoxHeader">
                                <div className="LocationReviewsBoxHeaderBox">
                                    <h1>{A.service_type}</h1>
                                    <h2>BHO Air Duct Cleaning</h2>
                                </div>
                                <div className="LocationReviewsBoxHeaderBox">
                                    <h1>⭐⭐⭐⭐⭐</h1>
                                    <h2>{A.date}</h2>
                                </div>
                            </div>

                            <div className="LocationReviewsBoxLeft">
                                <h3>Open Now 24/7 • Top Rated • Same Day Service</h3>
                                <h2>Review Rated 5 By {A.customer_name}</h2>
                                <h4>{A.review}</h4>
                                <div className="LocationReviewsBoxLeftBox">
                                    <div className="LocationReviewsBoxLeftBoxBox">
                                        <h5>Service Provided:</h5>
                                        <h6>{A.service_type}</h6>
                                    </div>
                                    <div className="LocationReviewsBoxLeftBoxBox">
                                        <h5>Technician</h5>
                                        <h6>{A.tech_name}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <NavigatorPath />
        </div>
    )
}

