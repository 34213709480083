import React, { useState } from 'react'
import closeMenu from '../../Images/closeMenu.png'
import next from '../../Images/next.png'
import './Menu.scss';
import AreaData from '../../Components/ServicesPage/AreaData'
import { useLocation, useNavigate } from 'react-router-dom';
import Image2Logo from '../../ImagesLogos/Image2Logo.png'

export default function Menu({ CitiePhone, navigateTo, setShowMenu, WebSitePages }) {
    const location = useLocation()
    const navigate = useNavigate()
    console.log(location.pathname);

    const [showMakers, setshowMakers] = useState(false);
    return (
        <div className="Menu">
            <div className="MenuBoxShadow" onClick={() => setShowMenu(false)}></div>
            <div className="MenuList">
                <div className="MenuListTop">
                    <div className="MenuMain">
                        {location.pathname == "/local" || location.pathname == "/book" ?
                            <div className="MenuMainBox">
                                <a onClick={() => {
                                    setShowMenu(false)
                                    navigate(`/local${location.search}`)
                                    window.scrollTo(0, 0)
                                }}>HOME PAGE</a>
                            </div>
                            :
                            <div className="MenuMainBox">
                                <a href={`/local${location.search}`}>HOME PAGE</a>
                            </div>
                        }

                        {location.pathname == "/local" || location.pathname == "/book" ?
                            <div className="MenuMainBox">
                                <a onClick={() => {
                                    setShowMenu(false)
                                    window.location.hash = 'ourservices'
                                }}>OUR SERVICES</a>
                            </div>
                            :
                            <div className="MenuMainBox" onClick={() => {
                                setshowMakers(true)
                            }}>
                                <h1 >SERVICES</h1>
                                <img src={next} alt="Air Duct Cleaning In BHO, | Air Duct Repair In BHO, | Air Duct Services In BHO, | Air Duct Inspection In BHO," />
                            </div>
                        }

                        <div className="MenuMainBox">
                            <a onClick={() => {
                                setShowMenu(false)
                                navigate(`/book${location.search}`)
                            }}>SCHEDULE ONLINE</a>
                        </div>

                        {location.pathname == "/local" || location.pathname == "/book" ?
                            <div className="MenuMainBox">
                                <a onClick={() => {
                                    setShowMenu(false)
                                    window.location.hash = 'reviews'
                                }}>CUSTOMER REVIEWS</a>
                            </div>
                            :
                            <div className="MenuMainBox">
                                <a href={`/reviews/local${location.search}`}>CUSTOMER REVIEWS</a>
                            </div>
                        }

                        {location.pathname == "/local" || location.pathname == "/book" ?
                            <div className="MenuMainBox">
                                <a onClick={() => {
                                    setShowMenu(false)
                                    window.location.hash = 'contact'
                                }}>CONTACT US</a>
                            </div>
                            :
                            <div className="MenuMainBox">
                                <a href={`/contact${location.search}`}>CONTACT US</a>
                            </div>
                        }

                        {location.pathname == "/local" || location.pathname == "/book" ?
                            null
                            :
                            <div className="MenuMainBox">
                                <a href={`/blog${location.search}`}>BLOG</a>
                            </div>
                        }
                        {location.pathname == "/local" || location.pathname == "/book" ?
                            <div className="MenuMainBox">
                                <a onClick={() => {
                                    setShowMenu(false)
                                    window.location.hash = 'about'
                                }}>ABOUT US</a>
                            </div>
                            :
                            null
                        }
                        <div className="MenuMainBoxImg">
                            <img src={Image2Logo} alt="" />
                        </div>

                    </div>
                    <div className="MenuFooter">
                        <h5>Copyright © 2024 BHO Air Duct Cleaning. All rights reserved.</h5>
                    </div>

                    {/* showCategories */}
                    {showMakers ?
                        <div className="MenuMakers">
                            <div className="MenuMakersHeader">
                                <img src={next} alt="Air Duct Cleaning In BHO, | Air Duct Repair In BHO, | Air Duct Services In BHO, | Air Duct Inspection In BHO," onClick={() => {
                                    setshowMakers(false)
                                }} />
                                <h1 onClick={() => {
                                    // navigateTo("")
                                }}>Services</h1>
                            </div>
                            <div className="MenuMakersList">
                                <div className="MenuMakersListBox">
                                    {AreaData.map((A, index) =>
                                        <a href={`${A.Path}${location.search}`} className="Header1BoxMenuBox">
                                            {A.TitleShortVery}
                                        </a>
                                    )}
                                </div>

                            </div>

                        </div>
                        : null}
                </div>
                <div className="MenuListFooter">
                    <a href={`tel:${CitiePhone}`}>CALL NOW {CitiePhone}</a>
                    <a onClick={() => {
                        setShowMenu(false)
                        navigate(`/book${location.search}`)
                    }}>SCHEDULE ONLINE</a>
                    <p>
                        Monday - Sunday
                        <br />
                        8:00 a.m. - 8:00 p.m.
                    </p>
                </div>

            </div>
        </div>
    )
}
